import Firebase       from '../../components/Firebase'

const firebase = new Firebase()
const db = firebase.db

class ResourcesRepository {

    releaseIsValid(release) {
        if(!release.id || !release.status || !release.branches.iOS || !release.branches.Android || !release.releaseNotes) return false
        if(release.id.length === 0 || release.status.length === 0 || release.branches.iOS.length === 0 || release.branches.Android.length === 0 || release.releaseNotes.length === 0) return false
        return true
    }

    async createRelease(release) {
        if(!this.releaseIsValid(release)) throw new Error('Release data missing')
        const releaseId = release.id
        delete release.id
        await db.collection('base/frontier/releases').doc(releaseId).set(release);
        return { ...release, id: releaseId};
    }

    async getRelease(id) {
        const document = await db.collection('base/frontier/releases/').doc(id).get()
        if (!document.exists) throw new Error('Release does not exist')
        const data = document.data()
        data.id = id
        return data
    }

    async getReleases() {
        const querySnapshot = await db.collection('base/frontier/releases').get()

        const releases = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
        }))

        return releases
    }

    async updateRelease(release) {
        if(!this.releaseIsValid(release)) throw new Error('Release data missing')
        const releaseId = release.id
        delete release.id
        await db.collection('base/frontier/releases/')
            .doc(releaseId)
            .update(release)
        return { ...release, id: releaseId};
    }

    async getResourceVersions(platform, branch) {
        const functionCall = firebase.functions.httpsCallable('getResourceVersions')
        const result = await functionCall({ platform, branch })
        if (!result.data) throw new Error(`Unable to get resource versions`)
        return JSON.parse(result.data)
    }

    async getAppResourceVersion(appCode, collection) {
        const path = `apps/${appCode}/platforms/frontier/${collection}`
        try {
            const querySnapshot = await db.collection(path)
                .orderBy("version", "desc")
                .limit(1)
                .get()

            if(querySnapshot.isEmpty || querySnapshot.docs.length < 1) return 0
            return querySnapshot.docs?.[0]?.data()?.version

        } catch (_) {
            return 0
        }
    }
}

export default ResourcesRepository