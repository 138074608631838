import Firebase from '../../components/Firebase';
import AppRepository from '../../repositories/App';
import Util from '../../util';

const firebase = new Firebase();
const db = firebase.db;
const storage = firebase.storage;

class ImagesRepository {

    constructor(appCode) {
        this.appCode = appCode;
        this.schemaPath =  'base/frontier/images';
        this.storageAppPath = `${appCode}/frontier/images`;
        this.storageBasePath = `base/frontier/images`;
        this.appRepository = new AppRepository(appCode);
    }

    getAllAppSchemaVersions = async () => {
        const versions = [];
        const versionFolders = await storage.child(this.storageAppPath).listAll();

        if (versionFolders) {
            versionFolders.prefixes.forEach(folderRef => {
                versions.push(folderRef.name);
            });
        }
        versions.sort((a, b) => b-a);

        return versions;
    }

    getRequiredAppSchemaVersionIfExists = async () => {
        const versions = await this.getAllAppSchemaVersions();
        const resourceVersions = await this.appRepository.getRequiredResourceVersions()
        const requiredVersion = resourceVersions["images"]
        if(versions.includes(requiredVersion.toString())) return requiredVersion;
        return null;
    }

    getLatestAppSchemaVersion = async () => {
        const versions = await this.getAllAppSchemaVersions();
        return versions?.[0];
    }

    getLatestAppSchema = async () => {
        const version = await this.getLatestAppSchemaVersion();

        if (version) {
            const doc = await db.collection(this.schemaPath)
                .doc(String(version))
                .get();

            if (!doc.exists) return;

            return Util.sortSchema(doc.data(), true);
        }
    }

    getRequiredAppSchema = async () => {
        const resourceVersions = await this.appRepository.getRequiredResourceVersions()
        const version = resourceVersions["images"]

        const doc = await db.collection(this.schemaPath)
            .doc(String(version))
            .get();

        if (!doc.exists) throw new Error('No schema available');

        return Util.sortSchema(doc.data(), true);
    }

    getLatestBaseSchema = async () => {
        const querySnapshot = await db.collection(this.schemaPath)
            .orderBy('version', 'desc')
            .limit(1)
            .get();
        const docs = [];

        querySnapshot.forEach(doc => {
            docs.push(doc.data());
        });

        return Util.sortSchema(docs[0], true);
    }

    saveSchema = async schema => {
        await db.collection(this.schemaPath)
            .doc(String(schema.version))
            .set(schema);
    }

    getImage = async (image, version, isBase) => {
        const imageUrl = await storage
            .child(this.getImagePath(image, version, isBase))
            .getDownloadURL();

        return imageUrl;
    }

    saveImage = async (image, imageName, version, isBase) => {
        const imagePath = this.getImagePath(imageName, version, isBase);
        await storage.child(imagePath).put(image);
        return 'Upload successful';
    }

    copyImages = async ({ srcApp, destApp, srcVersion, destVersion, images }) => {
        const copyImages = firebase.functions.httpsCallable('copyImages');
        await copyImages({ srcApp, destApp, srcVersion, destVersion, images });
        return 'Copied successful';
    }

    compressImagesToZip = async version => {
        const compressImagesToZip = firebase.functions.httpsCallable('compressImagesToZip');
        const result = await compressImagesToZip({ appCode: this.appCode, version });
        const zipPath = result.data;
        const zipUrl = await storage.child(zipPath).getDownloadURL();
        return zipUrl;
    }

    getImagePath = (image, version, isBase) => {
        return `${isBase ? this.storageBasePath : this.storageAppPath}/${version}/${image}`;
    }
    
}

export default ImagesRepository;