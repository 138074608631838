import Firebase from '../../components/Firebase';
import AppRepository from '../../repositories/App';
import Util from '../../util';

const firebase = new Firebase();
const db = firebase.db;

class RemoteConfigRepository {
    constructor(platform, appCode) {
        this.platform = platform;
        this.appCode = appCode;
        this.appConfigPath = `apps/${appCode}/platforms/${platform}/remoteConfig`;
        this.baseConfigPath =  `base/${platform}/remoteConfig`;
        this.storageAppPath = `${appCode}/${platform}/remoteConfig`;
        this.storageBasePath = `base/${platform}/remoteConfig`;
        this.appRepository = new AppRepository(appCode);
    }

    async getCurrentBaseRemoteConfig(callback) {
        const resourceVersions = await this.appRepository.getRequiredResourceVersions()
        const version = resourceVersions["remoteConfig"]

        db.collection(this.baseConfigPath)
        .doc(String(version))
        .get()
        .then(doc => {
            if (!doc.exists) return this.getCurrentRemoteConfig(true, callback);
            callback(Util.sortSchema(doc.data()), null);
        })
        .catch(function(error) {
            callback(null, error.message);
        });
    }

    getCurrentRemoteConfig(isBase, callback) {
        db.collection(isBase? this.baseConfigPath : this.appConfigPath).doc('current')
        .get()
        .then((documentSnapshot) => {
            if (!documentSnapshot.exists) throw new Error('No configuration found');

            callback(Util.sortSchema(documentSnapshot.data()), null)
        })
        .catch((error) => {
            callback(null, error)
        })
    }

    getBaseSchemaByVersion = async (version) => {
        const doc = await db.collection(this.baseConfigPath)
            .doc(String(version))
            .get();

        if (!doc.exists) throw new Error('No config available');

        return Util.sortSchema(doc.data());
    }

    updateSchema(config, callback) {
        this._backupCurrentSchema()
        .then(() => {
            db.collection(this.baseConfigPath).doc("current")
            .set(config)
            .then(() => {
                const configJson = JSON.stringify(config);
                const blob = new Blob([ configJson ], { type: 'application/json' });
                const storagePath = `${this.storageBasePath}/${config.version}`;
                firebase.storage.child(`${storagePath}/remoteConfig.json`).put(blob);
                callback(null);
            })
            .catch((error) => {
                callback(error);
            })
        })
    }

    async _backupCurrentSchema() {
        const currentSchemaDocument = await db.collection(this.baseConfigPath).doc("current").get();

        if(!currentSchemaDocument.exists) return;

        const currentSchema = currentSchemaDocument.data();

        await db.collection(this.baseConfigPath)
            .doc(currentSchema.version.toString())
            .set(currentSchema);
    }

    saveAppValues(config, callback) {
        db.collection(this.appConfigPath).doc("current")
        .set(config)
        .then(() => {
            const configJson = JSON.stringify(config);
            const blob = new Blob([ configJson ], { type: 'application/json' });
            const storagePath = `${this.storageAppPath}/${config.version}`;
            firebase.storage.child(`${storagePath}/remoteConfig.json`).put(blob);
            callback(null)
        })
        .catch((error) => {
            callback(error)
        })
    }

}

export default RemoteConfigRepository;