import React          from 'react';
import PropTypes      from 'prop-types';
import { Row, Col }   from 'react-bootstrap';

import UploadingAsset from '../UploadingAsset';
import DownloadButton from '../buttons/DownloadButton';

class IOSCertificates extends React.Component {
    handleCertificateFileChange = (certificateKey, { file }) => {
        const { onCertificateFileChange } = this.props;

        onCertificateFileChange(certificateKey, file);
    }

    generateCertificateProps = certificateKey => {
        const certificates = this.props.certificates;

        return {
            displayAssetName : certificates[certificateKey].displayName,
            hasBeenUploaded  : !!certificates[certificateKey].url,
            onFileChange     : this.handleCertificateFileChange.bind(this, certificateKey)
        }
    }

    render = () => {
        const certificates = this.props.certificates;

        return (
            <>
                <Row>
                    <Col lg={5} sm={12} >
                        <UploadingAsset { ...this.generateCertificateProps('distribution') } />
                    </Col>
                </Row>
                <Row className='border-top border-bottom'>
                    <Col>
                        <Row>
                            <Col lg={5} sm={12} >
                                <UploadingAsset { ...this.generateCertificateProps('applePayProd') } />
                            </Col>
                            <Col className='pt-3 d-flex align-items-start justify-content-center' sm={{order: 3}} >
                                {certificates.csrProd.url &&
                                    <DownloadButton
                                        url={certificates.csrProd.url}
                                        fileName={certificates.csrProd.fileName}
                                        title={`Download ${certificates.csrProd.displayName}`}
                                    />
                                }
                            </Col>
                            <Col lg={{span: 4, order: 3}} sm={7} >
                                <UploadingAsset { ...this.generateCertificateProps('csrProd') } isRequired={false} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={5} sm={12} >
                                <UploadingAsset { ...this.generateCertificateProps('applePayDev') } />
                            </Col>
                            <Col className='pt-3 d-flex align-items-start justify-content-center' sm={{order: 3}} >
                                {certificates.csrDev.url &&
                                    <DownloadButton
                                        url={certificates.csrDev.url}
                                        fileName={certificates.csrDev.fileName}
                                        title={`Download ${certificates.csrDev.displayName}`}
                                    />
                                }
                            </Col>
                            <Col lg={{span: 4, order: 3}} sm={7} >
                                <UploadingAsset { ...this.generateCertificateProps('csrDev') } isRequired={false} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={5} sm={12} >
                        <UploadingAsset { ...this.generateCertificateProps('pushProd') } />
                    </Col>
                </Row>
                <Row>
                    <Col md={5} sm={12} >
                        <UploadingAsset { ...this.generateCertificateProps('pushDev') } />
                    </Col>
                </Row>
            </>
        );
    }
}

IOSCertificates.propTypes = {
    certificates            : PropTypes.exact({
        distribution : PropTypes.shape({
            displayName : PropTypes.string.isRequired,
            url         : PropTypes.string
        }).isRequired,
        applePayProd : PropTypes.shape({
            displayName : PropTypes.string.isRequired,
            url         : PropTypes.string
        }).isRequired,
        applePayDev : PropTypes.shape({
            displayName : PropTypes.string.isRequired,
            url         : PropTypes.string
        }).isRequired,
        csrProd : PropTypes.shape({
            displayName : PropTypes.string.isRequired,
            url         : PropTypes.string
        }).isRequired,
        csrDev : PropTypes.shape({
            displayName : PropTypes.string.isRequired,
            url         : PropTypes.string
        }).isRequired,
        pushProd : PropTypes.shape({
            displayName : PropTypes.string.isRequired,
            url         : PropTypes.string
        }).isRequired,
        pushDev : PropTypes.shape({
            displayName : PropTypes.string.isRequired,
            url         : PropTypes.string
        }).isRequired
    }).isRequired,
    onCertificateFileChange : PropTypes.func.isRequired
}

export default IOSCertificates;