class Util {
    basePath = 'base';
    IOS = 'iOS';
    ANDROID = 'Android';
    FRONTIER = 'frontier';
    KEY_TYPES = {
        string : 'string',
        color  : 'color',
        bool   : 'bool',
        number : 'number'
    }
    ENV_TYPES = {
        dev        : 'dev',
        staging    : 'staging',
        production : 'production'
    }

    isBase(appCode) {
        return appCode === this.basePath;
    }

    castValueToType = (value, typeString) => {
        if (
            typeString === 'text' ||
            typeString === this.KEY_TYPES.string ||
            typeString === this.KEY_TYPES.color ||
            typeString === 'hex'
        ) {
            return String(value);
        } else if (typeString === this.KEY_TYPES.number) {
            return Number(value);
        } else if (
            typeString === 'boolean' ||
            typeString === this.KEY_TYPES.bool
        ) {
            return Boolean(value);
        }
    }

    getDefaultValueForType = type => {
        let defaultValue = '';

        switch (type) {
            case this.KEY_TYPES.string: {
                defaultValue = '';
                break;
            }
            case this.KEY_TYPES.color: {
                defaultValue = '#FFFFFF';
                break;
            }
            case this.KEY_TYPES.bool: {
                defaultValue = false;
                break;
            }
            case this.KEY_TYPES.number: {
                defaultValue = 0;
                break;
            }
            default: {
                defaultValue = '';
            }
        }

        return defaultValue;
    }

    formatKeyName = keyName => {
        return keyName.replace(/ /g, '_');
    }

    formatImageName = name => {
        return name.replace(/ |-|_/g, '').toLowerCase();
    }

    formatString = string => {
        const regExp = /<!\[CDATA\[.*?]]>|(\r?\n)|(\\?["'])|\\?\\n?|&(?!amp;)/gs;

        return string.replace(regExp, match => {
            if (match === '\n' || match === '\r' || match === '\r\n') return '\\n';

            if (match === '"' || match === "'" || match === '\\') {
                return `\\${match}`;
            }

            if (match === '&') {
                return '&amp;';
            }

            return match;
        });
    }

    checkOverridenVersion = (version, platform) => {
        if (version === '' || version === null) return true;

        const versionNumber = Number(version);

        if (versionNumber < 1 || (platform === this.ANDROID && version.includes('.'))) return false;

        return true;
    }

    createImageName = (section, image) => {
        return `${section}_${image}.png`;
    }

    parseImageName = imageName => {
        const imageNameParts = imageName.split('_');

        if (imageNameParts.length > 1) {
            return {
                section : imageNameParts[0],
                image   : imageNameParts[1].slice(0, -4)
            }
        }
    }

    addTooltips = (appValues, baseValues, latestBaseValues, isNested) => {
        const newConfigValues = {};

        Object.keys(appValues).forEach(moduleName => {
            if (!isNested) {
                const tooltip = baseValues[moduleName]?.tooltip || latestBaseValues[moduleName]?.tooltip;

                return newConfigValues[moduleName] = {
                    ...appValues[moduleName],
                    tooltip
                };
            }

            newConfigValues[moduleName] = {};

            Object.keys(appValues[moduleName]).forEach(keyName => {
                const tooltip = baseValues[moduleName]?.[keyName]?.tooltip || latestBaseValues[moduleName]?.[keyName]?.tooltip;

                newConfigValues[moduleName][keyName] = {
                    ...appValues[moduleName][keyName],
                    tooltip
                };
            });
        });

        return newConfigValues;
    }

    removeTooltips = (configValues, isNested) => {
        const newConfigValues = {};

        Object.keys(configValues).forEach(moduleName => {
            newConfigValues[moduleName] = { ...configValues[moduleName] };
            delete newConfigValues[moduleName].tooltip;

            if (!isNested) return;

            Object.keys(configValues[moduleName]).forEach(keyName => {
                newConfigValues[moduleName][keyName] = {
                    ...configValues[moduleName][keyName]
                };
                delete newConfigValues[moduleName][keyName].tooltip;
            });
        });

        return newConfigValues;
    }

    formatValues = (values, isBase, isNested) => {
        return isBase ? values : this.removeTooltips(values, isNested);
    }

    formatSchema = (values, version, isBase, isNested) => {
        const formattedValues = this.formatValues(values, isBase, isNested);
        const formattedSchema = { values: formattedValues, version };

        return formattedSchema;
    }

    sortStrings = (strings) => {
        const sortedFields = Object.keys(strings).sort();

        return sortedFields.reduce((acc, item) => {
            const { translations } = strings[item];
            const sortedLanguages = Object.keys(translations).sort();
            const sortedTranslations = sortedLanguages.reduce((translationsAcc, language) => {
                translationsAcc[language] = translations[language];

                return translationsAcc;
            }, {});

            acc[item] = {
                ...strings[item],
                translations : sortedTranslations
            };

            return acc;
        }, {});
    }

    sortSchema = (schema, isNestedValues) => {
        const sortedFields = Object.keys(schema.values).sort();
        const values = sortedFields.reduce((acc, item) => {
            if (isNestedValues) {
                acc[item] = {
                    ...schema.values[item],
                    values: this.sortObjectKeys(schema.values[item].values)
                };

                return acc;
            }

            acc[item] = this.sortObjectKeys(schema.values[item]);

            return acc;
        }, {});

        return { ...schema, values };
    }

    sortObjectKeys = (obj) => {
        const sortedKeys = Object.keys(obj).sort();

        return sortedKeys.reduce((acc, item) => {
            acc[item] = obj[item];

            return acc;
        }, {});
    }
}

export default new Util();