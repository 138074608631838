import Cookies from 'universal-cookie';
const cookies = new Cookies();

class CookieProvider {
    static set = function(key, value) {
        cookies.set(key, value, { path: '/' });
    }
    static get = function(key) {
        return cookies.get(key)
    }
}

export default CookieProvider;