import React                            from 'react';
import PropTypes                        from 'prop-types';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

import UpgradeBlock from '../../UpgradeBlock';

import './ManageResourcesLayout.css';

class ManageResourcesLayout extends React.Component {
    renderHeader = () => {
        const { title, button } = this.props;

        return (
            <Row>
                <Col className='manageResourcesHeader'>
                    <h1>{title}</h1>
                    {button}
                </Col>
            </Row>
        );
    }

    renderUpgrade = () => {
        const { isUpgrade, onUpgrade } = this.props;

        if (isUpgrade) {
            return (
                <Row>
                    <Col>
                        <UpgradeBlock onUpgrade={onUpgrade} />
                    </Col>
                </Row>
            );
        }
    }

    render = () => {
        const { children, isLoading } = this.props;

        return (
            <Container className='manageResources'>
                {this.renderHeader()}
                {this.renderUpgrade()}
                <Row>
                    <Col>
                        {isLoading ?
                            <div className='loaderWrapper'>
                                <Spinner animation='border' variant='primary' />
                            </div> :
                            children
                        }
                    </Col>
                </Row>    
            </Container>
        );
    }    
}

ManageResourcesLayout.propTypes = {
    title     : PropTypes.string.isRequired,
    button    : PropTypes.element,
    isUpgrade : PropTypes.bool,
    isLoading : PropTypes.bool,
    onUpgrade : PropTypes.func
}

export default ManageResourcesLayout;