import React      from 'react';
import PropTypes  from 'prop-types';
import { Button } from 'react-bootstrap';

function DownloadButton({ url, fileName, title, className }) {
    return (
        <Button
            variant='info'
            href={url}
            download={fileName || true}
            className={className}
        >
            {title || 'Download'}
        </Button>
    );
}

DownloadButton.propTypes = {
    url       : PropTypes.string.isRequired,
    fileName  : PropTypes.string,
    title     : PropTypes.string,
    className : PropTypes.string
}

export default DownloadButton;