import React from 'react';
import { Row, Col, Form, DropdownButton, Dropdown } from 'react-bootstrap';

import Util  from '../../../../../util';

function StringInput({ values, environmentSpecific, options, onChange }) {
    let inputElements = null;

    if (environmentSpecific) {
        inputElements = Object.keys(Util.ENV_TYPES).map(envKey => {
            const env = Util.ENV_TYPES[envKey];

            return (
                <Row key={envKey}>
                    <Col>
                        <Form.Control
                            type='text'
                            value={values[env]}
                            onChange={onChange.bind(null, env)}
                            disabled={!!options}
                        />
                    </Col>
                    {options ?
                        <Col sm={3}>
                            <DropdownButton title='Choose...' className='float-right'>
                                {options.map((option) =>
                                    <Dropdown.Item
                                        key={values[env]+option}
                                        onClick={onChange.bind(null, env, {target: { value: option }})}
                                    >
                                        {option}
                                    </Dropdown.Item>
                                )}
                            </DropdownButton>
                        </Col> :
                        null
                    }
                    <Col sm={3}>
                        <span>{env}</span>
                    </Col>
                </Row>
            );
        });
    } else {
        inputElements = (
            <Row>
                <Col>
                    <Form.Control
                        type='text'
                        value={values[Util.ENV_TYPES.production]}
                        onChange={onChange.bind(null, Util.ENV_TYPES.production)}
                        disabled={!!options}
                    />
                </Col>
                {options ?
                    <Col sm={3}>
                        <DropdownButton title='Choose...' className='float-right'>
                            {options.map((option) =>
                                <Dropdown.Item
                                    key={values[Util.ENV_TYPES.production]+option}
                                    onClick={onChange.bind(null, Util.ENV_TYPES.production, {target: { value: option }})}
                                >
                                    {option}
                                </Dropdown.Item>
                            )}
                        </DropdownButton>
                    </Col> :
                    null
                }
            </Row>
        )
    }

    return inputElements;
}

export default StringInput;