import React from 'react';
import { Row, Col, Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';

class AddConfigItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemName : ''
        }
    }

    handleNameChange = event => {
        this.setState({ itemName: event.target.value });
    }

    handleAddItem = itemType => {
        const { itemName } = this.state;
        const { module, onAddItem } = this.props;

        onAddItem(module, itemName, itemType);
        this.setState({ itemName: '' });
    }

    render = () => {
        const { itemTypes } = this.props;
        const { itemName } = this.state;

        return (
            <Row className='newKeyContainer'>
                <Col sm={{ span: 6, offset: 3 }}>
                    <InputGroup>
                        <Form.Control
                            type='text'
                            placeholder='New key'
                            value={itemName}
                            onChange={this.handleNameChange}
                        />
                        <InputGroup.Append>
                            <DropdownButton title='+' variant='success' className='float-right'>
                                {itemTypes.map(itemType => {
                                    const displayedItemType = itemType[0].toUpperCase() + itemType.slice(1);
                                    
                                    return (
                                        <Dropdown.Item
                                            key={itemType}
                                            onClick={this.handleAddItem.bind(this, itemType)}
                                        >
                                            {displayedItemType}
                                        </Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
        );
    }
}

export default AddConfigItem;