import React                 from 'react';
import { Row, Col } from 'react-bootstrap';
import AppRepository         from '../../../repositories/App';
import SigningRepository     from '../../../repositories/Signing';
import ImagesRepository from '../../../repositories/Images';
import { ciRepository }      from '../../../repositories/CI';
import ReleasesRepository               from '../../../repositories/Releases';

import ManageResourcesLayout from '../../layouts/ManageResourcesLayout';
import AppInfo               from '../../AppInfo';
import BuildApp              from '../../BuildApp';

import Util                  from '../../../util';
import Firebase from '../../Firebase';

const firebase = new Firebase();

class ManageApp extends React.Component {
    constructor(props) {
        super(props);
        const { params } = this.props.match;
        this.appRepository = new AppRepository(params.appCode);
        this.signingRepository = new SigningRepository(params.appCode);
        this.imagesRepository = new ImagesRepository(params.appCode);
        this.releasesRepository = new ReleasesRepository();
        this.state = {
            appInfo: {
                appCode         : params.appCode,
                displayName     : '',
                release         : null,
                releaseHistory  : [],
            },
            platform: 'iOS',
            buildScheme: 'Debug',
            overriddenVersion: null,
            isPushingBuild: false,
            releases: [],
            release: null,
            resourceVersions: {},
            appResourceVersions: {}
        };
    }

    isValidReleaseBuild = () => {
        const resourceVersions = this.state.resourceVersions
        const appResourceVersions = this.state.appResourceVersions
        if(Object.keys(resourceVersions).length === 0 || Object.keys(appResourceVersions).length === 0) return false
        for (const key in resourceVersions) {
            if (resourceVersions.hasOwnProperty(key) && appResourceVersions.hasOwnProperty(key)) {
              if (resourceVersions[key] !== appResourceVersions[key]) {
                return false
              }
            }
        }
        return true
    }

    componentDidMount = async () => {
        Promise.all([
            this.fetchAppInfo(),
            this.getReleases(),
            this.getAppResourceVersions()
        ]);
    }

    fetchAppInfo = async () => {
        try {
            let appInfo = await this.appRepository.fetchAppInfo();
            if(!appInfo.releaseHistory) appInfo.releaseHistory = [];
            this.setState({ appInfo }, function() { this.setRelease() });
        }
        catch (error) {
            alert(error.message);
        }
    }

    getReleases = async () => {
        try {
            const releases = await this.releasesRepository.getReleases()
            const releasesToDisplay = releases.filter((release) => release.status !== "declined")
            this.setState({ releases: releasesToDisplay }, function() { this.setRelease() })
        } catch (error) {
            alert(error.message)
        }
    }

    setRelease() {
        if(this.state.releases.length === 0) return
        if(!this.state.appInfo || !this.state.appInfo.release) return
        const release = this.state.releases.find((aRelease) => {
            return aRelease.id === this.state.appInfo.release
        })
        this.setState({ release }, function() { this.getResourceVersions() })
    }

    getResourceVersions = async () => {
        if(!this.state.release || !this.state.release.branches || !this.state.release.branches.iOS) return
        try {
            const resourceVersions = await this.releasesRepository.getResourceVersions('iOS', this.state.release.branches.iOS)
            this.setState({ resourceVersions })
        } catch (error) {
            alert(error.message)
        }
    }

    getAppResourceVersions = async () => {
        const { params } = this.props.match;
        const collections = ['buildConfig', 'strings', 'remoteConfig', 'fonts'];
        
        const versionsPromises = collections.map(collection => 
            this.releasesRepository.getAppResourceVersion(params.appCode, collection).catch(() => null)
        );
    
        const versionsResults = await Promise.allSettled(versionsPromises);
        const updatedVersions = versionsResults.reduce((accumulator, result, index) => {
            if (result.status === 'fulfilled' && result.value !== null) {
                accumulator[collections[index]] = result.value;
            }
            return accumulator;
        }, {});

        const imagesVersion = await this.imagesRepository.getRequiredAppSchemaVersionIfExists();
        updatedVersions['images'] = imagesVersion ? parseInt(imagesVersion) : 0;

        this.setState({ appResourceVersions: updatedVersions });
    }
    

    handleSaveAppInfo = async () => {
        try {
            await this.appRepository.saveAppInfo(this.state.appInfo);

            alert('App info saved');
        } catch (error) {
            alert(error.message);
        }
    }

    markReleaseReleased = async (release) =>  {
        let newAppInfo = this.state.appInfo
        newAppInfo.releaseHistory.push({
            release: release,
            released: firebase.firestore.Timestamp.now()
        })
        this.setState({appInfo: newAppInfo})
        await this.handleSaveAppInfo()
    }

    handlePassDistributionCertificateToBitrise = async () => {
        try {
            const certificateSlug = await this.signingRepository.passDistributionCertificateToBitrise()
            return certificateSlug;
        }
        catch (error) {
            alert(error.message);
        }
    }

    triggerBuilds = async (buildCertificateSlug, branch) => {
        const { params } = this.props.match;
        const { appInfo, buildScheme, overriddenVersion } = this.state;
        const appName = appInfo.displayName || params.appCode;
        const isOverridenVersionValid = Util.checkOverridenVersion(overriddenVersion, params.platform);

        if (!isOverridenVersionValid) return alert('Invalid version number');

        this.setState({ isPushingBuild: true });

        try {
            const buildNumber = await ciRepository.triggerBuilds(
                this.state.platform,
                params.appCode,
                buildScheme,
                overriddenVersion,
                buildCertificateSlug,
                appInfo.release,
                branch
            );

            alert(`Building app ${appName} with build number: ${buildNumber}`);
        }
        catch (error) {
            alert(error.message);
        }

        this.setState({ isPushingBuild: false });
    }

    handleTriggerBuild = async () => {
        const platform = this.state.platform;
        try {
            if (platform === Util.IOS) {
                const certificateSlug = await this.handlePassDistributionCertificateToBitrise();
                const buildCertificateSlug = certificateSlug.data;
                if (buildCertificateSlug) {
                    await this.triggerBuilds(buildCertificateSlug, this.state.release.branches.iOS);
                }
            }
            if (platform === Util.ANDROID) {
                await this.triggerBuilds(null, this.state.release.branches.Android);
            }
        }
        catch (error) {
            alert(error.message);
        }
    }

    handleDisplayNameChange = displayName => {
        const appInfo = this.state.appInfo;

        this.setState({ appInfo: { ...appInfo, displayName } });
    }

    handleReleaseChange = releaseId => {
        this.setState({ appInfo: { ...this.state.appInfo, release: releaseId } }, function() { this.setRelease() })
    }

    handleBuildSchemeChange = buildScheme => this.setState({ buildScheme });

    handlePlatformChange = platform => this.setState({ platform });

    handleOverriddenVersionChange = overriddenVersion => this.setState({ overriddenVersion: overriddenVersion === '' ? null : overriddenVersion });

    render = () => {
        const { appInfo, platform, buildScheme, isPushingBuild, overriddenVersion, releases, release, resourceVersions, appResourceVersions } = this.state;
        const overriddenVersionStep = this.state.platform === Util.ANDROID ? '1' : '.01';
        const overriddenVersionFormat = this.state.platform === Util.ANDROID ? '1000' : '10.0';

        return (
            <ManageResourcesLayout title={'Manage App'}>
                <Row>
                    <Col>
                        <AppInfo
                            appCode={appInfo.appCode}
                            displayName={appInfo.displayName || ''}
                            releases={releases}
                            release={appInfo.release || 'Choose a release'}
                            releaseHistory={appInfo.releaseHistory}
                            onDisplayNameChange={this.handleDisplayNameChange}
                            onReleaseChange={this.handleReleaseChange}
                            onSaveAppInfo={this.handleSaveAppInfo}
                            onMarkReleased={this.markReleaseReleased}
                        />
                        {release ? 
                            <div>
                                <h4 className='mt-4'>Release notes & known issues</h4>
                                <p>{release.releaseNotes}</p>
                                <h4 className='mt-4'>Resources required</h4>
                                {(Object.keys(resourceVersions) ?? {}).map((k) =>
                                    <Row key={'resourceVersions-'+k}>
                                        <Col>
                                            <b>{k}</b>
                                        </Col>
                                        <Col className="text-right">
                                            {resourceVersions[k]} { resourceVersions[k] !== appResourceVersions[k] ? "⛔️" : "" }
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        :
                            <p>Awaiting release information...</p>
                        }
                    </Col>
                    <Col>
                        <BuildApp
                            platform={platform}
                            buildScheme={buildScheme}
                            overriddenVersion={overriddenVersion}
                            overriddenVersionStep={overriddenVersionStep}
                            overriddenVersionFormat={overriddenVersionFormat}
                            isPushingBuild={isPushingBuild}
                            isValidReleaseBuild={this.isValidReleaseBuild()}
                            onOverriddenVersionChange={this.handleOverriddenVersionChange}
                            onBuildSchemeChange={this.handleBuildSchemeChange}
                            onPlatformChange={this.handlePlatformChange}
                            onTriggerBuild={this.handleTriggerBuild}
                            helpUrl={this.state.platform === Util.ANDROID ? "https://confluence.external-share.com/content/bec5cfd6-fbe8-4b2f-ba2a-dc598aa19957" : "https://confluence.external-share.com/content/23f1945c-9b4f-46ae-a468-21815dc4f39a" }
                        />
                    </Col>
                </Row>                
            </ManageResourcesLayout>
        );
    }
}

export default ManageApp;