import EventEmitter from 'events'
import BuildConfigRepository from '../../../repositories/BuildConfig';
import Util from '../../../util.js';

class BuildConfigStore extends EventEmitter {

    changeActionKey = "CHANGE";
    messageActionKey = "ASYNC_MESSAGE";
    configRepository = null;
    _message = null;

    set message(newMessage) {
        this._message = newMessage;
        this.emit(this.messageActionKey);
    }
    
    get message() {
        const message = this._message;
        this._message = null;
        return message ? message : "Meta Error: Tried to get a message/error, but none was found";
    }

    constructor(platform, appCode) {
        super();
        this.appCode = appCode;
        this.configRepository = new BuildConfigRepository(platform, appCode);
        this.isBase = () => Util.isBase(appCode);
        this.state = {
            config: {
                values: { },
                version: null
            },
            baseConfig: {
                values: { },
                version: null
            },
            newModule: '',
            hasCreatedNewVersion: false
        }
    }
    
    fetchConfig = () => {
        this.configRepository.getLatestBuildConfig(this.isBase(), (result, error) => {
            if (error) return this.message = error.message;

            this.state.config = result;
            this.emit(this.changeActionKey, { config: this.state.config });
        })

        if (!this.isBase()) {
            this.configRepository.getCurrentBaseBuildConfig(async (result, error) => {
                if (error) return this.message = error.message;

                this.state.baseConfig = result;
                this.emit(this.changeActionKey, { baseConfig: this.state.baseConfig });
                await this.fetchTooltips();
            })
        }
    }

    fetchTooltips = async () => {
        const { config, baseConfig: latestBaseConfig } = this.state;

        let baseConfig = latestBaseConfig;

        if (latestBaseConfig.version !== config.version) {
            try {
                baseConfig = await this.configRepository.getBaseSchemaByVersion(config.version);
            }
            catch (error) {
                return this.message = error.message;
            }
        }

        const newValues = Util.addTooltips(config.values, baseConfig.values, latestBaseConfig.values, true);
        const newConfig = { values: newValues, version: config.version };

        this.state.config = newConfig;
        this.emit(this.changeActionKey, { config: this.state.config });
    }

    performSynchronisation = () => {
        const { baseConfig, config } = this.state;
        const newConfig = { ...baseConfig };

        for (const module in baseConfig.values) {
            if (module in config.values) {
                const keysWithinCurrentModule = config.values[module];
                const keysWithinBaseModule = baseConfig.values[module];
                for (const configKey in keysWithinCurrentModule) {
                    if (keysWithinBaseModule[configKey] &&
                        keysWithinBaseModule[configKey].canBeOverriden &&
                        keysWithinCurrentModule[configKey].canBeOverriden
                    ) {
                        newConfig.values[module][configKey].values = keysWithinCurrentModule[configKey].values;
                        if (keysWithinBaseModule[configKey].environmentSpecific !== keysWithinCurrentModule[configKey].environmentSpecific) {
                            const productionValue = newConfig.values[module][configKey].values[Util.ENV_TYPES.production];

                            Object.keys(newConfig.values[module][configKey].values).map(env => {
                                return newConfig.values[module][configKey].values[env] = productionValue;
                            });
                        }
                    }
                }
            }
        } 

        this.state.config = newConfig;
        this.emit(this.changeActionKey, { config: this.state.config });
    }

    updateConfigValue(module, configKey, environment, value) {
        const { config } = this.state;
        const keyObj = config.values[module][configKey];
        const typeOfValue = keyObj.type;
        const environmentSpecific = keyObj.environmentSpecific;
        const canBeOverriden = keyObj.canBeOverriden;
        const formattedValue = Util.castValueToType(value, typeOfValue);
        
        if (!canBeOverriden) this.incrementVersionIfNecessary();

        if (environmentSpecific) {
            keyObj.values[environment] = formattedValue;
        } else {
            Object.keys(keyObj.values).map(env => keyObj.values[env] = formattedValue);
        }
        this.emit(this.changeActionKey, { config });
    }

    updateTooltip(module, configKey, tooltip) {
        const { config } = this.state;
        const keyObj = config.values[module][configKey];

        keyObj.tooltip = tooltip;
        this.emit(this.changeActionKey, { config });
    }

    updateFlag(module, configKey, flag, value) {
        this.state.config.values[module][configKey][flag] = value;
        if (flag === 'environmentSpecific' && !value) {
            const sampleValue = this.state.config.values[module][configKey].values[Util.ENV_TYPES.production];
            Object.keys(this.state.config.values[module][configKey].values).map(env => {
                return this.state.config.values[module][configKey].values[env] = sampleValue;
            });
        }
        this.incrementVersionIfNecessary();
        this.emit(this.changeActionKey, { config: this.state.config });
    }

    addModule(module) {
        const { config } = this.state;
        const formattedModule = Util.formatKeyName(module);

        if (config.values[formattedModule]) return this.message = `Module ${formattedModule} already exists`;

        this.incrementVersionIfNecessary();
        config.values[formattedModule] = {};
        this.emit(this.changeActionKey, { config });
    }

    addNewConfigKey(module, configKey, type, value) {
        const { config } = this.state;
        const formattedKey = Util.formatKeyName(configKey);

        if (config.values[module][formattedKey]) return this.message = `Key ${formattedKey} already exists`;

        this.incrementVersionIfNecessary();
        const castValue = Util.castValueToType(value, type);
        const values = {};

        Object.keys(Util.ENV_TYPES).map(env => values[Util.ENV_TYPES[env]] = castValue);
        config.values[module][formattedKey] = { type, values, canBeOverriden: false, environmentSpecific: false };
        this.emit(this.changeActionKey, { config });
    }

    deleteModule(module) {
        this.incrementVersionIfNecessary();
        for (const configKey in this.state.config.values[module]) {
            this.deleteConfigKey(module, configKey, false);
        }

        if (Object.keys(this.state.config.values[module]).length === 0) {
            const newConfig = this.state.config;
            delete newConfig.values[module];
            this.state.config = newConfig;
        }

        this.emit(this.changeActionKey, { config: this.state.config });
    }

    deleteConfigKey(module, configKey, emitChange = true) {
        this.incrementVersionIfNecessary();
        delete this.state.config.values[module][configKey];
        if (emitChange) this.emit(this.changeActionKey, { config: this.state.config });
    }

    incrementVersionIfNecessary() {
        if (this.state.hasCreatedNewVersion) return;
        this.message = "This will create a new version 🍄";
        this.state.config.version += 1;
        this.state.hasCreatedNewVersion = true;
    }

    save() {
        const { config } = this.state;
        const formattedConfig = Util.formatSchema(config.values, config.version, this.isBase(), true);

        const callback = (error) => {
            if (error) return this.message = error.message;
            this.state.hasCreatedNewVersion = false;
            this.message = "Saved";
            this.emit(this.changeActionKey, { config });
        }
        this.configRepository.saveConfig(this.isBase(), formattedConfig, callback);
    }
}

export default BuildConfigStore;