import React from 'react';
import { Row, Col, ListGroup, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import UploadingAsset from './UploadingAsset';

function UploadingAssets({ assets, uploading, onFileChange, onUploadFiles, developmentMode }) {
    const assetKeys = Object.keys(assets);

    return (
        <>
            <Row className='uploadingAssets'>
                <Col>
                    <ListGroup>
                        {assetKeys.map(assetKey => {
                            return (
                                <ListGroup.Item key={assetKey}>
                                    {assetKeys.length > 1 ? <h5>{assetKey}</h5> : null }
                                    {Object.keys(assets[assetKey].files).map(file => {
                                        return (
                                            <UploadingAsset
                                                key={assetKey + file}
                                                asset={file}
                                                hasBeenUploaded={assets[assetKey].files[file].hasBeenUploaded}
                                                keyName={assetKey}
                                                version={assets[assetKey].version}
                                                manualUploadPermitted={assets[assetKey].manualUploadPermitted}
                                                manageLink={assets[assetKey].manageLink}
                                                onFileChange={onFileChange}
                                                developmentMode={developmentMode}
                                            />
                                        );
                                    })}
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        size='lg'
                        className='float-right uploadButton'
                        disabled={uploading}
                        onClick={onUploadFiles}
                    >
                        {uploading ? 'Uploading...' : 'Upload' }
                    </Button>
                </Col>
            </Row>
        </>
    );
}

UploadingAssets.propTypes = {
    assets          : PropTypes.shape({
        key : PropTypes.exact({
            files : PropTypes.shape({
                fileName : PropTypes.exact({
                    hasBeenUploaded : PropTypes.bool
                })
            }).isRequired,
            version : PropTypes.number,
            manualUploadPermitted : PropTypes.bool,
            manageLink : PropTypes.string
        })
    }),
    uploading       : PropTypes.bool,
    onFileChange    : PropTypes.func.isRequired,
    onUploadFiles   : PropTypes.func.isRequired,
    developmentMode : PropTypes.bool
}

UploadingAssets.defaultProps = {
    assets          : {},
    uploading       : false,
    developmentMode : false
}

export default UploadingAssets;


/*
assets = {
    key : {
        files : {
            fileName: {}
        },
        version : 1 (optional),
        manualUploadPermitted : false (optional),
        manageLink : '' (optional)
    }
}
*/