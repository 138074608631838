import React from 'react';
import { Row, Col, Form, ListGroup, InputGroup, Button } from 'react-bootstrap';

class AddConfigModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            moduleName : ''
        }
    }

    handleNameChange = event => {
        this.setState({ moduleName: event.target.value });
    }

    handleAddModule = () => {
        const { moduleName } = this.state;
        const { onAddModule } = this.props;

        onAddModule(moduleName);
        this.setState({ moduleName: '' });
    }

    render = () => {
        const { moduleName } = this.state;

        return (
            <ListGroup.Item>
                <Row>
                    <Col sm={{ span: 6, offset: 3 }}>
                        <InputGroup>
                            <Form.Control
                                type='text'
                                onChange={this.handleNameChange}
                                value={moduleName}
                                placeholder='New section'
                            />
                            <InputGroup.Append>
                                <Button block variant='success' onClick={this.handleAddModule}>+</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
            </ListGroup.Item>
        );
    }
}

export default AddConfigModule;