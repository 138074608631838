import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import UploadAssets from '../../UploadAssets';

class ManageFirebase extends React.Component {

    constructor(props) {
        super(props);
        const { params } = this.props.match;
        this.platform = params.platform;
        this.appCode = params.appCode;
        this.assets = {
            'firebase' : {
                files : {
                    'google-services.json' : {},
                    'GoogleService-Info.plist' : {}
                }
            }           
        }
    }

    render = () => {
        return (
            <Container>
                <Row>
                    <Col>
                        <h1>{`${this.appCode} Firebase Config`}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <a href="https://confluence.external-share.com/content/dbdf291c-aec1-44ba-96ab-d2c206394988" target="_blank" rel="noopener noreferrer">Firebase Project Setup</a> | <a href="https://confluence.external-share.com/content/fd28a55a-ee36-424c-9049-7aecd4836516" target="_blank" rel="noopener noreferrer">Android Help</a> | <a href="https://confluence.external-share.com/content/7aeb0123-fe6b-4e45-bf31-76f280a93354" target="_blank" rel="noopener noreferrer">iOS Help</a>
                    </Col>
                </Row>
                <UploadAssets
                    assets={this.assets}
                    platform={this.platform}
                    appCode={this.appCode}
                />
            </Container>
        );
    }
}

export default ManageFirebase;
