import React       from 'react';
import PropTypes   from 'prop-types';
import { FaTimes } from 'react-icons/fa';

function DeleteButton({ onClick }) {
    return (
        <FaTimes 
            style={{ color: 'red' }}
            className='deleteButton'
            onClick={onClick}
        />
    );
}

DeleteButton.propTypes = {
    onClick : PropTypes.func.isRequired
}

export default DeleteButton;