import React from 'react';
import Button from 'react-bootstrap/Button';

class Home extends React.Component {
  render() {
    return (
      <div>
        <h1>AssetDelivery</h1>
        <Button href="/apps">Go to Apps</Button>
      </div>
    );
  }
}

export default Home;
