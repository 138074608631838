import React                from 'react';
import { InputGroup, Form } from 'react-bootstrap';

function FontStyleField({
    fileName,
    fontSize,
    onFileNameChange,
    onSizeChange
}) {
    return (
        <InputGroup>
            <Form.Control 
                type='text'
                placeholder='File Name'
                onChange={onFileNameChange}
                value={fileName}
            />
            <Form.Control
                type='number'
                onChange={onSizeChange}
                value={fontSize}
            />
        </InputGroup>
    )
}

export default FontStyleField;