import React from 'react';
import PropTypes from 'prop-types';

import './UploadFileBlock.css';

function UploadFileBlock({ title, onFileInputChange, ...rest }) {
    return (
        <div className='rounded mt-3 mb-3 p-3 border uploadFileBlock'>
            <p>{title}</p>
            <div className='ml-2'>
                <input type='file' onChange={onFileInputChange} {...rest} />
            </div>
        </div>
    );
}

UploadFileBlock.propTypes = {
    title             : PropTypes.string,
    onFileInputChange : PropTypes.func.isRequired
}

export default UploadFileBlock;