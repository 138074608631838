import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware                           from 'redux-thunk';
import reducers                                  from '../reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [ thunkMiddleware ];

export default function configureStore() {
    const store = createStore(reducers, composeEnhancers(
        applyMiddleware(...middleware)
    ));

    return store;
}

