import Firebase from '../../components/Firebase';

import Util     from '../../util';

const firebase = new Firebase();

class CiRepository {
    async triggerBuilds(platform, appCode, buildScheme, overriddenVersion, certificateSlug, releaseVersion, branch) {
        const triggering = firebase.functions.httpsCallable('triggerBuild');
        const ciSystem = platform === Util.IOS ? 'Bitrise' : platform === Util.ANDROID ? 'CircleCi' : null;

        try {
            if (!ciSystem) throw new Error('Platform undefined');
 
            const result = await triggering({ platform, appCode, buildScheme, overriddenVersion, certificateSlug, releaseVersion, branch });

            if (!result.data) throw new Error(`Unable to push the build to ${ciSystem}`);

            const buildNumber = ciSystem === 'Bitrise' ? result.data.build_number : result.data.number;

            return buildNumber;

        }
        catch (error) {
            throw error;
        }
    }
}

export default CiRepository;