import React     from 'react';
import PropTypes from 'prop-types';
import { Form }  from 'react-bootstrap';

import './Tooltip.css';

function Tooltip(props) {
    const { value, editable, onChange, className } = props;

    const handleChange = event => {
        onChange(event.target.value);
    }

    if (!editable && !value) {
        return null;
    }

    const tooltipField = editable ? (
        <Form.Control
            type        = 'text'
            value       = {value || ''}
            onChange    = {handleChange}
            placeholder = 'Tooltip'
        />
    ) : <span className='tooltipLabel'>{value}</span>;

    return (
        <div className={className}>
            {tooltipField}
        </div>
    );
}

Tooltip.propTypes = {
    value     : PropTypes.string,
    editable  : PropTypes.bool,
    onChange  : PropTypes.func,
    className : PropTypes.string
}

export default Tooltip;
