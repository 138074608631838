import React                                                from 'react';
import PropTypes                                            from 'prop-types';
import { Row, Col, Form, Button, DropdownButton, Dropdown } from 'react-bootstrap';

const PLATFORMS = [ 'iOS', 'Android' ];
const BUILD_SCHEMES = [ 'Debug', 'Staging', 'Release', 'ReleaseDebug' ];

class BuildApp extends React.Component {

    handleOverriddenVersionChange = event => {
        const onOverriddenVersionChange = this.props.onOverriddenVersionChange;

        onOverriddenVersionChange(event.target.value);
    }

    render = () => {
        const {
            platform,
            buildScheme,
            overriddenVersion,
            overriddenVersionStep,
            overriddenVersionFormat,
            isPushingBuild,
            isValidReleaseBuild,
            onPlatformChange,
            onBuildSchemeChange,
            onTriggerBuild,
            helpUrl
        } = this.props;

        const overriddenVersionPlaceholder = `[Optional] Fill in this field with format ${overriddenVersionFormat} if you would like to rewrite the version.`;

        return (
            <div>
                <h2>Build App</h2>
                <a href={helpUrl} target="_blank" rel="noopener noreferrer">Testing &amp; Deployment Help</a>
                <Form.Group className='row no-gutters'>
                    <label className='col-sm-4 col-form-label'>Version number</label>
                    <Col className='col-sm-8'>
                        <Form.Control
                            type='number'
                            step={overriddenVersionStep}
                            placeholder={overriddenVersionPlaceholder}
                            value={overriddenVersion || ''}
                            onChange={this.handleOverriddenVersionChange}
                        />
                    </Col>
                </Form.Group>
                <Row>
                    <label className='col-sm-4 col-form-label'>Platform</label>
                    <Col className='col-sm-8'>
                        <DropdownButton title={platform} variant='success'>
                            {PLATFORMS.map(platform => {
                                return (
                                    <Dropdown.Item
                                        key={platform}
                                        onClick={() => onPlatformChange(platform)}
                                    >
                                        {platform}
                                    </Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </Col>
                </Row>
                <Row>
                    <label className='col-sm-4 col-form-label'>Build scheme</label>
                    <Col className='col-sm-8 mt-2'>
                        <DropdownButton title={buildScheme || 'Choose scheme'} variant='success'>
                            {BUILD_SCHEMES.map(buildScheme => {
                                return (
                                    <Dropdown.Item
                                        key={buildScheme}
                                        onClick={onBuildSchemeChange.bind(this, buildScheme)}
                                    >
                                        {buildScheme}
                                    </Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </Col>
                </Row>
                <Row className='mt-3' >
                    <Col>
                        <Button
                            className='mr-1'
                            variant='primary'
                            disabled={isPushingBuild || !isValidReleaseBuild}
                            onClick={onTriggerBuild}
                        >
                            Push Build
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

BuildApp.propTypes = {
    onBuildSchemeChange       : PropTypes.func.isRequired,
    onPlatformChange          : PropTypes.func.isRequired,
    onTriggerBuild            : PropTypes.func.isRequired,
    platform                  : PropTypes.string,
    buildScheme               : PropTypes.string,
    overriddenVersion         : PropTypes.string,
    overriddenVersionStep     : PropTypes.string,
    overriddenVersionFormat   : PropTypes.string,
    isPushingBuild            : PropTypes.bool,
    onOverriddenVersionChange : PropTypes.func
}

export default BuildApp;