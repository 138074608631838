import React from 'react';
import { Row, Col, Button, Alert, ListGroup } from 'react-bootstrap';

import ConfigModule    from './ConfigModule';
import AddConfigModule from './AddConfigModule';

function ConfigModuleList({
    title,
    config,
    isUpgradeAvailable,
    isModificationAllowed,
    keyTypes,
    onSave,
    onUpgrade,
    onAddModule,
    onAddKey,
    onDeleteModule,
    onDeprecateKey,
    onEnvSpecificChange,
    onChangeConfigKeyValue,
    onTooltipChange
}) {
    function renderHeader() {
        return (
            <Row className='header'>
                <Col>
                    <h1>{`${title} (Version ${config.version || 0})`}</h1>
                </Col>
                <Col sm={1} xs={4} className='saveWrapper'>
                    <Button
                        variant='success'
                        onClick={onSave}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        )
    }

    function renderUpgrade() {
        if (!isUpgradeAvailable) return;
        
        return (
            <Alert variant='primary'>
                There is an upgrade available 
                <Button onClick={onUpgrade} className='ml-2'>Upgrade</Button>
            </Alert>
        );
    }

    return (
        <Row className='configContainer'>
            <Col lg={{ offset: 2, span: 8 }} md={{ offset: 1, span: 10 }} sm={12}>
                {renderHeader()}
                {renderUpgrade()}
                <ListGroup>
                    {Object.keys(config.values).map(module => {
                        return (
                            <ConfigModule
                                key={module}
                                moduleName={module}
                                module={config.values[module]}
                                isModificationAllowed={isModificationAllowed}
                                keyTypes={keyTypes}
                                onDeleteModule={onDeleteModule}
                                onDeprecateKey={onDeprecateKey}
                                onEnvSpecificChange={onEnvSpecificChange}
                                onChangeConfigKeyValue={onChangeConfigKeyValue}
                                onTooltipChange={onTooltipChange}
                                onAddKey={onAddKey}
                            />
                        );
                    })}
                    {isModificationAllowed ? <AddConfigModule onAddModule={onAddModule} /> : null}
                </ListGroup>
            </Col>
        </Row>
    );
}

export default ConfigModuleList;
