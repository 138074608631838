import React                             from 'react';
import PropTypes                         from 'prop-types';
import { Row, Col, Button, Form, Alert } from 'react-bootstrap';

import UploadingAsset             from '../UploadingAsset';
import SaveButton                 from '../buttons/SaveButton';

class AndroidSigning extends React.Component {
    state = {
        password         : '',
        keyAlias         : '',
        keystorePassword : ''
    }

    handleFCMServerKeyChange = event => {
        const { onFCMServerKeyChange } = this.props;

        onFCMServerKeyChange(event.target.value);
    }

    handleKeystoreFileChange = ({ file }) => {
        const { onKeystoreFileChange } = this.props;

        onKeystoreFileChange(file);
    }

    handleCredentialChange = (name, event) => this.setState({ [name]: event.target.value });

    handleGenerateKeystore = () => {
        const { password, keyAlias, keystorePassword } = this.state;
        const onGenerateKeystore = this.props.onGenerateKeystore;

        if (password && keyAlias && keystorePassword) {
            onGenerateKeystore(password, keyAlias, keystorePassword);
            this.cleanForm();
        }
    }

    handleGenerateSha1AndKeyHash = () => {
        const { password, keyAlias, keystorePassword } = this.state;
        const onGenerateSha1AndKeyHash = this.props.onGenerateSha1AndKeyHash;

        if (password && keyAlias && keystorePassword) {
            onGenerateSha1AndKeyHash(password, keyAlias, keystorePassword);
            this.cleanForm();
        }
    }

    cleanForm = () => this.setState({ password: '', keyAlias: '', keystorePassword: '' });

    render = () => {
        const { keystore, sha1, keyHash, fcmServerKey, onFCMServerKeySave } = this.props;
        const { password, keyAlias, keystorePassword } = this.state;
        const pressToGenerateMessage = "Press button below to generate this key"
        const sha1Message = sha1 ? sha1 : pressToGenerateMessage
        const keyHashMessage = keyHash ? keyHash : pressToGenerateMessage

        const generateKeystoreButton = !keystore.url &&
            <Button
                variant='primary'
                onClick={this.handleGenerateKeystore}
                disabled={!password || !keyAlias || !keystorePassword}
                className='mt-3'
            >
                Generate KeyStore
            </Button>;
        const generateSha1Button = keystore.url &&
            <Button
                variant='primary'
                onClick={this.handleGenerateSha1AndKeyHash}
                disabled={!password || !keyAlias || !keystorePassword}
                className='mt-3'
            >
                Generate SHA-1 & KeyHash
            </Button>;

        return (
            <>
                <Row>
                    <Col>
                        <Alert variant='info'>
                            <p>Upload your keystore file and enter its credential.</p>
                            <p className='mb-0'>If you like to generate a new keystore please enter new preferred credentials and press generate keystore button.</p>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col lg={5} sm={12} >
                        <UploadingAsset
                            displayAssetName={keystore.displayName}
                            hasBeenUploaded={!!keystore.url}
                            isRequired={false}
                            onFileChange={this.handleKeystoreFileChange}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col lg={7} sm={12}>
                        <p>SHA-1 for Firebase:
                            <Alert variant={sha1?'success':'info'}>
                                <p className='font-weight-bold adjustableText'>{sha1Message}</p>
                            </Alert>
                        </p>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col lg={5} sm={12}>
                        <p>Facebook Production Key Hash :
                            <Alert variant={keyHash?'success':'info'}>
                                <span className='font-weight-bold adjustableText'>{keyHashMessage}</span>
                            </Alert>
                        </p>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col lg={5} sm={12}>
                        <p>Facebook Dev Key Hash :
                             <Alert variant='success'>
                                <span className='font-weight-bold adjustableText'>
                                    FrLu5jlhg+Sw3yhv0AwwKWp3Cdo=</span>
                            </Alert>
                        </p>
                    </Col>
                </Row>

                <Row className='mt-3' >
                    <Col lg={5} sm={12} >
                        <Form>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type='password'
                                    onChange={this.handleCredentialChange.bind(this, 'password')}
                                    value={password}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Key Alias</Form.Label>
                                <Form.Control
                                    type='text'
                                    onChange={this.handleCredentialChange.bind(this, 'keyAlias')}
                                    value={keyAlias}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>KeyStore Password</Form.Label>
                                <Form.Control
                                    type='password'
                                    onChange={this.handleCredentialChange.bind(this, 'keystorePassword')}
                                    value={keystorePassword}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col className='d-flex flex-column justify-content-center align-items-start'>
                        {generateKeystoreButton}
                        {generateSha1Button}
                    </Col>
                </Row>
                <Row className='mt-3' >
                    <Col lg={5} xs={8} >
                        <Form.Group>
                            <Form.Label>FCM Server Key</Form.Label>
                            <Form.Control
                                type='text'
                                onChange={this.handleFCMServerKeyChange}
                                value={fcmServerKey}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={4} className='d-flex flex-column justify-content-end align-items-start pb-3' >
                        <SaveButton onClick={onFCMServerKeySave} />
                    </Col>
                </Row>
            </>
        );
    }
}

AndroidSigning.propTypes = {
    fcmServerKey             : PropTypes.string.isRequired,
    keystore                 : PropTypes.shape({
        displayName          : PropTypes.string.isRequired,
        url                  : PropTypes.string
    }).isRequired,
    sha1                     : PropTypes.string.isRequired,
    onFCMServerKeyChange     : PropTypes.func.isRequired,
    onFCMServerKeySave       : PropTypes.func.isRequired,
    onKeystoreFileChange     : PropTypes.func.isRequired,
    onGenerateKeystore       : PropTypes.func.isRequired,
    onGenerateSha1AndKeyHash : PropTypes.func.isRequired
}

export default AndroidSigning;