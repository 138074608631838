import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import * as firebaseui from 'firebaseui';

const config = {
    apiKey: "AIzaSyA7G3X2GCGGFCIkQaY9s4J8xVCV15nRv9Y",
    authDomain: "assetdelivery-dev.firebaseapp.com",
    databaseURL: "https://assetdelivery-dev.firebaseio.com",
    projectId: "assetdelivery-dev",
    storageBucket: "assetdelivery-dev.appspot.com",
    messagingSenderId: "744904939973",
    appId: "1:744904939973:web:dfb29f7fd9314f36f153ac",
    measurementId: "G-Z255FM289Z"
};

class Firebase {
    constructor() {
        if (!firebase.apps.length) { firebase.initializeApp(config); }
        this.Auth = firebase.auth;
        this.auth = firebase.auth();
        this.functions = firebase.app().functions('europe-west2');
        this.db = firebase.firestore();
        this.firestore = firebase.firestore;
        if(process.env.NODE_ENV !== "test") {
            this.ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(this.auth);
        }
        this.storage = firebase.storage().ref();
    }
}

export default Firebase;