import Util from '../../util.js';
import Firebase from '../../components/Firebase';
import AppRepository from '../../repositories/App';
const firebase = new Firebase();
const db = firebase.db;
const storage = firebase.storage;

class FontsRepository {
    constructor(appCode) {
        const isBase = appCode === Util.basePath;
        const pathBase = isBase ? 'base/' : `apps/${appCode}/platforms/`;
        this.appCode = appCode;
        this.baseFontsDatabasePath = `base/frontier/fonts`;
        this.fontsDatabasePath = `${pathBase}frontier/fonts`;
        this.fontsStoragePath = `${appCode}/frontier/fonts`;
        this.appRepository = new AppRepository(appCode);
    }

    getBaseFontStylesForCurrentFontsVersion = async () => {
        const resourceVersions = await this.appRepository.getRequiredResourceVersions()
        const version = resourceVersions["fonts"]

        const doc = await db.collection(this.baseFontsDatabasePath)
            .doc(String(version))
            .get();

        if (!doc.exists) throw new Error('No font style map available');

        return Util.sortSchema(doc.data());
    }

    getBaseSchemaByVersion = async (version) => {
        const doc = await db.collection(this.baseFontsDatabasePath)
            .doc(String(version))
            .get();

        if (!doc.exists) throw new Error('No font style map available');

        return Util.sortSchema(doc.data());
    }

    getLatestFontStyleMap = async (isBase) => {
        const schemaPath = isBase ? this.baseFontsDatabasePath : this.fontsDatabasePath;
        const querySnapshot = await db.collection(schemaPath)
            .orderBy('version', 'desc')
            .limit(1)
            .get();

        const docData = querySnapshot?.docs?.[0]?.data();

        if (!docData) throw new Error('No font style map available');
        
        return Util.sortSchema(docData);
    }

    saveFontStyles = async (fontStylesSchema, previousVersion, fonts) => {
        const copyFonts = firebase.functions.httpsCallable('copyFonts');

        await db.collection(this.fontsDatabasePath)
            .doc(String(fontStylesSchema.version))
            .set(fontStylesSchema);
        await this.saveFontStylesFile(fontStylesSchema);

        if (fonts) {
            await this.saveFontsZip(fonts, fontStylesSchema.version);
        } else if (previousVersion < fontStylesSchema.version) {
            await copyFonts({
                    appCode: this.appCode,
                    srcVersion: previousVersion,
                    destVersion: fontStylesSchema.version
                });
        }
    }

    saveFontStylesFile = async fontStylesSchema => {
        const fontStyles = fontStylesSchema.values || fontStylesSchema.map;
        const dic = JSON.stringify(fontStyles);
        const fontStylesBlob = new Blob([dic], {type: 'application/json'});
        const storagePath = `${this.fontsStoragePath}/${fontStylesSchema.version}`;

        await storage.child(`${storagePath}/FontStyles.json`).put(fontStylesBlob);
    }

    saveFontsZip = async (fonts, version) => {
        const storagePath = `${this.fontsStoragePath}/${version}`;
        const fontsBlob = new Blob([fonts], {type: 'application/zip'});

        await storage.child(`${storagePath}/Archive.zip`).put(fontsBlob);
    }
}

export default FontsRepository;