import {
    FETCH_APPS_SUCCESS,
    SET_APP,
    ADD_NEW_APP
} from '../actions/AppsActions';

const initialState = {
    apps : [],
    app  : undefined
};

export default function apps(state = initialState, { payload, type }) {
    switch (type) {
        case FETCH_APPS_SUCCESS: {
            return { ...state, apps: payload };
        }
        case SET_APP: {
            return { ...state, app: payload };
        }
        case ADD_NEW_APP: {
            return { ...state, apps: payload };
        }
        default: {
            return state;
        }
    }
}