import React                        from 'react';
import PropTypes                    from 'prop-types';
import { Form, InputGroup, Button } from 'react-bootstrap';

import './AddMultipleFieldForm.css';

class AddMultipleFieldForm extends React.Component {
    state = {
        values: {}
    }

    handleValueChange = (name, event) => {
        const values = this.state.values;

        this.setState({ values: {
            ...values,
            [name] : event.target.value
        }});
    }

    handleAddFields = () => {
        const values = this.state.values;
        const onAdd = this.props.onAdd;

        onAdd(values);
        
        this.setState({ values: {} });
    }

    render = () => {
        const { values } = this.state;
        const { fields, buttonName, className } = this.props;
        const fieldClassName = fields.length > 1 && 'w-100';

        return (
            <InputGroup className={`addMultipleFieldForm ${className}`}>
                {fields.map(field => {
                    return (
                        <Form.Control
                            key={field.name}
                            type='text'
                            onChange={this.handleValueChange.bind(this, field.name)}
                            value={values[field.name] || ''}
                            placeholder={field.placeholder || 'Type value'}
                            className={fieldClassName}
                        />
                    );
                })}
                <InputGroup.Append>
                    <Button block variant='success' onClick={this.handleAddFields}>{buttonName}</Button>
                </InputGroup.Append>
            </InputGroup>
        );
    }
}

AddMultipleFieldForm.propTypes = {
    fields     : PropTypes.arrayOf(PropTypes.exact({
        name        : PropTypes.string.isRequired,
        placeholder : PropTypes.string
    })).isRequired,
    buttonName : PropTypes.string,
    className  : PropTypes.string,
    onAdd      : PropTypes.func.isRequired
}

AddMultipleFieldForm.defaultProps = {
    buttonName        : '+'
}

export default AddMultipleFieldForm;