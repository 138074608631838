import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'react-bootstrap';

function UpgradeBlock({ onUpgrade }) {
    return (
        <Alert variant='primary'>
            There is an upgrade available 
            <Button onClick={onUpgrade} className='ml-2'>Upgrade</Button>
        </Alert>
    );
}

UpgradeBlock.propTypes = {
    onUpgrade : PropTypes.func.isRequired
}

export default UpgradeBlock;