import Firebase from '../../components/Firebase';

const firebase = new Firebase();

class AssetsRepository {

    constructor(platform, appCode) {
        this.platform = platform;
        this.appCode = appCode;
    }

    async getFile(key, fileName, version) {
        try {
            const fileUrl = await firebase.storage
                .child(this.getPath(key, fileName, version))
                .getDownloadURL();

            return fileUrl;
        }
        catch (error) {
            throw new Error('File not found');
        }
    }

    async saveFile(file, key, fileName, version) {
        try {
            await firebase.storage
                .child(this.getPath(key, fileName, version))
                .put(file);

            return 'Upload successful';
        }
        catch (error) {
            throw new Error('Upload failed');
        }
    }

    getPath(key, fileName, version) {
        return `${this.appCode}/${this.platform}/${key}/${version ? `${version}/` : ''}${fileName}`;
    }
}

export default AssetsRepository;