import React from 'react';
import { Button, Alert } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import PropTypes from 'prop-types';

function UploadingAsset({
    asset,
    keyName,
    hasBeenUploaded,
    version,
    manualUploadPermitted,
    manageLink,
    developmentMode,
    onFileChange
}) {
    const displayName = `${asset} ${version ? `(Version ${version}) ` : ''}`;

    function handleFileChange(event) {
        const file = event.target.files[0];

        onFileChange(keyName, asset, file);
    }

    return (
        <div className='uploadingAsset'>
            {hasBeenUploaded ? 
                <Alert variant='success'>
                    <FaCheck /> {displayName} is uploaded
                </Alert> :
                <Alert variant='info'>
                    {`${displayName} ${developmentMode ? 'to upload' : 'is required'}`}
                </Alert>
            }
            {manualUploadPermitted === false ? 
                <Button href={manageLink}>{`Manage ${keyName}`}</Button> :
                <input type='file' onChange={handleFileChange}/>
            }
        </div>
    );
}

UploadingAsset.propTypes = {
    asset                 : PropTypes.string.isRequired,
    keyName               : PropTypes.string.isRequired,
    hasBeenUploaded       : PropTypes.bool,
    version               : PropTypes.number,
    manualUploadPermitted : PropTypes.bool,
    manageLink            : PropTypes.string,
    developmentMode       : PropTypes.bool,
    onFileChange          : PropTypes.func.isRequired
}

UploadingAsset.defaultTypes = {
    hasBeenUploaded       : false,
    version               : undefined,
    manualUploadPermitted : true,
    manageLink            : '',
    developmentMode       : false,
}

export default UploadingAsset;