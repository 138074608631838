import React from 'react';

import ManageResourcesLayout from '../../layouts/ManageResourcesLayout';
import ResourceSection       from '../../ResourceSection';
import IOSCertificates       from '../../IOSCertificates';
import AndroidSigning        from '../../AndroidSigning';

import SigningRepository     from '../../../repositories/Signing';

class ManageSigning extends React.Component {
    constructor(props) {
        super(props);
        this.appCode = props.match.params.appCode;
        this.signingRepository = new SigningRepository(this.appCode);
        this.state = {
            iOSCertificates: {
                distribution : {
                    fileName    : 'ios-distribution-certificate.p12',
                    displayName : 'Distribution certificate',
                    url         : ''
                },
                applePayProd : {
                    fileName    : 'ios-applepay-certificate-prod.p12',
                    displayName : 'Apple Pay prod certificate',
                    url         : ''
                },
                applePayDev : {
                    fileName    : 'ios-applepay-certificate-dev.p12',
                    displayName : 'Apple Pay dev certificate',
                    url         : ''
                },
                csrProd : {
                    fileName    : 'ios-applepay-csr-prod.csr',
                    displayName : 'CSR',
                    url         : ''
                },
                csrDev : {
                    fileName    : 'ios-applepay-csr-dev.csr',
                    displayName : 'CSR',
                    url         : ''
                },
                pushProd : {
                    fileName    : 'ios-push-certificate-prod.p12',
                    displayName : 'Push notifications prod certificate',
                    url         : ''
                },
                pushDev : {
                    fileName    : 'ios-push-certificate-dev.p12',
                    displayName : 'Push notifications dev certificate',
                    url         : ''
                }
            },
            fcmServerKey: '',
            keystore: {
                fileName    : 'and_keystore.jks',
                displayName : 'Keystore file',
                url         : ''
            },
            sha1: '',
            keyHash: '',
            loading: true
        }
    }

    componentDidMount = async () => {
        await this.fetchIOSCertificates();
        await this.fetchKeystore();
        await this.fetchSha1AndKeyHash();
        await this.fetchFCMServerKey();

        this.setState({ loading: false });
    }

    fetchIOSCertificates = async () => {
        const newIOSCertificates = { ...this.state.iOSCertificates };
        const operations = Object.keys(newIOSCertificates).map(async certificateKey => {
            try {
                const url = await this.signingRepository.getFileUrl(newIOSCertificates[certificateKey].fileName);

                return newIOSCertificates[certificateKey].url = url;
            }
            catch (error) {
                return error;
            }
        });

        await Promise.all(operations);

        this.setState({ iOSCertificates: newIOSCertificates });
    }

    fetchKeystore = async () => {
        const keystore = this.state.keystore;

        try {
            const url = await this.signingRepository.getFileUrl(keystore.fileName);

            this.setState({
                keystore: {
                    ...keystore,
                    url
                }
            });
        }
        catch (error) {
            return error;
        }
    }

    fetchSha1AndKeyHash = async () => {
        try {
            const data = await this.signingRepository.getSha1AndKeyHash();
            const { keyHash, sha1 } = data
            if (sha1 && keyHash) {
                this.setState({ sha1, keyHash });
            }
        }
        catch (error) {
            this.showMessage(error.message);
        }
    }

    fetchFCMServerKey = async () => {
        try {
            const fcmServerKey = await this.signingRepository.getFCMServerKey();

            if (fcmServerKey) {
                this.setState({ fcmServerKey });
            }
        }
        catch (error) {
            this.showMessage(error.message);
        }
    }

    showMessage = message => alert(message);

    handleIOSCertificateChange = async (certificateKey, file) => {
        const newIOSCertificates = { ...this.state.iOSCertificates };

        try {
            const certificateUrl = await this.signingRepository.saveFile(file, newIOSCertificates[certificateKey].fileName);

            newIOSCertificates[certificateKey].url = certificateUrl;

            this.setState({ iOSCertificates: newIOSCertificates });
        }
        catch (error) {
            this.showMessage(error.message);
        }
    }

    handleFCMServerKeyChange = fcmServerKey => this.setState({ fcmServerKey });

    handleFCMServerKeySave = async () => {
        const fcmServerKey = this.state.fcmServerKey;

        if (!fcmServerKey) return;

        try {
            await this.signingRepository.saveFCMServerKey(fcmServerKey);

            this.showMessage('FCM Server Key saved');
        }
        catch (error) {
            this.showMessage(error.message);
        }
    }

    handleKeystoreFileChange = async file => {
        const keystore = this.state.keystore;

        try {
            const url = await this.signingRepository.saveFile(file, keystore.fileName);

            this.setState({
                keystore: {
                    ...keystore,
                    url
                }
            });
        }
        catch (error) {
            this.showMessage(error.message);
        }
    }

    handleGenerateKeystore = async (password, keyAlias, keystorePassword) => {
        try {
            const data = await this.signingRepository.generateKeystore(password, keyAlias, keystorePassword);
            const { sha1, keyHash } = data
            if (sha1 && keyHash) {
                this.setState({ sha1, keyHash });
                this.showMessage('Keystore generated');
            }
        }
        catch (error) {
            this.showMessage(error.message);
        }
    }

    handleGenerateSha1AndKeyHash = async (password, keyAlias, keystorePassword) => {
        const keystore = this.state.keystore;

        try {
            const data = await this.signingRepository.generateSha1AndKeyHash(password, keyAlias, keystorePassword);
            const { sha1, keyHash } = data
            if (sha1 && keyHash) {
                this.setState({
                    sha1,
                    keyHash,
                    keystore: {
                        ...keystore,
                        url: ''
                    }
                });
                this.showMessage('SHA-1 and Facebook KeyHash generated');
            }
        }
        catch (error) {
            this.showMessage(error.message);
        }
    }

    render = () => {
        const title = `${this.appCode} Certificates and Signing`;
        const { iOSCertificates, fcmServerKey, keystore, sha1, keyHash, loading } = this.state;

        return (
            <ManageResourcesLayout title={title} isLoading={loading} >
                <ResourceSection title='iOS'>
                    <a href="https://confluence.external-share.com/content/6fbdce6b-4a38-4bc7-afbf-18804be4baf2" target="_blank" rel="noopener noreferrer">Help</a>
                    <IOSCertificates
                        certificates={iOSCertificates}
                        onCertificateFileChange={this.handleIOSCertificateChange}
                    />
                </ResourceSection>
                <ResourceSection title='Android'>
                    <a href="https://confluence.external-share.com/content/802ed094-4a3a-4eb7-a732-566d48f6dd73" target="_blank" rel="noopener noreferrer">Help</a>
                    <AndroidSigning
                        fcmServerKey={fcmServerKey}
                        keystore={keystore}
                        sha1={sha1}
                        keyHash={keyHash}
                        onFCMServerKeyChange={this.handleFCMServerKeyChange}
                        onFCMServerKeySave={this.handleFCMServerKeySave}
                        onKeystoreFileChange={this.handleKeystoreFileChange}
                        onGenerateKeystore={this.handleGenerateKeystore}
                        onGenerateSha1AndKeyHash={this.handleGenerateSha1AndKeyHash}
                    />
                </ResourceSection>
            </ManageResourcesLayout>
        );
    }
}

export default ManageSigning;