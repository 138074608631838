import React from 'react'
import CookieProvider from '../CookieProvider';
import Firebase from '../Firebase';
const firebase = new Firebase();

class Auth extends React.Component {
    componentDidMount() {
        const hasBeenPassedAFromRedirect = this.props.location.state && this.props.location.state.from;
        const isNewLoginWithoutRedirect = !hasBeenPassedAFromRedirect && !firebase.ui.isPendingRedirect()
        if(hasBeenPassedAFromRedirect) {
            CookieProvider.set("redirectUrl", this.props.location.state.from.pathname);
        } else if (isNewLoginWithoutRedirect) {
            CookieProvider.set("redirectUrl", null);
        }
        const savedRedirectUrl = CookieProvider.get("redirectUrl") 
        const redirectUrl = (!savedRedirectUrl || savedRedirectUrl === 'null') ? "/" : savedRedirectUrl;
        var uiConfig = {
            signInOptions: [
                {
                    provider: firebase.Auth.EmailAuthProvider.PROVIDER_ID,
                    signInMethod: firebase.Auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                    forceSameDevice: false
                }
            ],
            signInSuccessUrl: redirectUrl
        };
        firebase.ui.start('#firebaseui-auth-container', uiConfig);
    }
    render() {
        return (
            <div>
                <link type="text/css" rel="stylesheet" href="https://cdn.firebase.com/libs/firebaseui/4.1.0/firebaseui.css" />
                <div id="firebaseui-auth-container"></div>
            </div>
        );
    }
}

export default Auth;