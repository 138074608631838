import React              from 'react';
import PropTypes          from 'prop-types';
import { Row, Col, Form, DropdownButton, Dropdown, Button } from 'react-bootstrap';

import SaveButton         from '../buttons/SaveButton';

class AppInfo extends React.Component {

    handleDisplayNameChange = event => {
        const onDisplayNameChange = this.props.onDisplayNameChange;
        onDisplayNameChange(event.target.value);
    }

    handleReleaseChange = releaseId => {
        this.props.onReleaseChange(releaseId);
    }

    releaseIsReleased = (releaseHistoryItem) => {
        return this.props.releaseHistory.find((aRelease) => aRelease.release === releaseHistoryItem)
    }

    render = () => {
        const { appCode, displayName, release, releaseHistory, releases, onSaveAppInfo, onMarkReleased } = this.props;

        return (
            <div className='pt-3'>
                <h2>App Info</h2>
                <Row className='mt-2'>
                    <Col className='col-sm-4 col-form-label'><b>App code</b></Col>
                    <Col>{appCode}</Col>
                </Row>
                <Row className='mt-2 mb-2'>
                    <Col className='col-sm-4 col-form-label'><b>Display Name</b></Col>
                    <Col>
                        <Form.Control
                            type='text'
                            onChange={this.handleDisplayNameChange}
                            placeholder='Display name of the app'
                            value={displayName}
                        />
                    </Col>
                </Row>
                <Row className='mt-2 mb-2'>
                    <Col className='col-sm-4 col-form-label'><b>Release</b></Col>
                    <Col>
                        <DropdownButton title={release} variant='success'>
                            {(releases ?? []).map(aRelease => {
                                return (
                                    <Dropdown.Item
                                        key={'releaseDropdown-'+aRelease.id}
                                        onClick={() => this.handleReleaseChange(aRelease.id)}
                                    >
                                        {aRelease.id}
                                    </Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}><b>Release History</b></Col>
                    <Col>
                        {(releaseHistory ?? []).map(releaseHistoryItem => {
                            return (
                                <div className='mb-2' key={'releaseHistory-'+releaseHistoryItem.release}>
                                    <span>{releaseHistoryItem.release}</span>
                                    {this.releaseIsReleased(releaseHistoryItem) ?
                                        <span className='float-right'>Preparing 🛠️</span>
                                    :
                                        <span className='float-right'>{releaseHistoryItem.released.toDate().toLocaleString()} ✅</span>
                                    }
                                    
                                </div>
                            )
                        })}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <SaveButton onClick={onSaveAppInfo} />
                    </Col>
                    <Col>
                        <Button className="float-right" onClick={ () => { onMarkReleased(release) }} variant="primary">{"Mark "+ release +" Released"}</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

AppInfo.propTypes = {
    appCode             : PropTypes.string.isRequired,
    displayName         : PropTypes.string.isRequired,
    onDisplayNameChange : PropTypes.func.isRequired,
    onSaveAppInfo       : PropTypes.func.isRequired,
    onMarkReleased      : PropTypes.func.isRequired
}

export default AppInfo;