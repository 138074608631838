import React, { useEffect, useState }   from 'react';
import {
    Row,
    Col,
    ListGroup,
    Button
}                                       from 'react-bootstrap';
import { Link }                         from 'react-router-dom';
import ReleasesRepository               from '../../../repositories/Releases';

function Releases() {

    const [releases, setReleases] = useState([])

    useEffect(() => {
        const releasesRepository = new ReleasesRepository()
        const getReleases = async () => {
            try {
                const releases = await releasesRepository.getReleases()
                setReleases(releases)
            } catch (error) {
                alert(error.message)
            }
        }
        getReleases()
    }, [])

    const statusImage = (status) => {
        if(status === "draft") {
            return "🛠"
        } else if(status === "ready") {
            return "✅"
        } else if(status === "declined") {
            return "❌"
        } else {
            return "❓"
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <h1>Releases</h1>
                    <p>Here you can manage releases of Frontier.</p>
                </Col>
                <Col>
                    <Button variant="success" className="float-right" href="releases/new">Create Release</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ListGroup>
                        {releases.map(release =>
                            <ListGroup.Item key={release.id} action as={Link} to={'releases/'+release.id}>
                                {statusImage(release.status) + " " + release.id}
                            </ListGroup.Item>
                            
                        )}
                    </ListGroup>
                </Col>
            </Row>
        </div>
    );
}

export default Releases;