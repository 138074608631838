import React from 'react';
import PropTypes from 'prop-types';

import UploadingAssets from './UploadingAssets';

import AssetsRepository from '../../repositories/Assets';

class UploadAssets extends React.Component {

    constructor(props) {
        super(props);
        this.assetsRepository = new AssetsRepository(this.props.platform, this.props.appCode);
        this.state = {
            assets : this.props.assets,
            selectedFiles : {},
            uploading : false
        }
    }

    componentDidMount = async () => {
        await this.fetchExistingFiles();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.assets !== this.props.assets) {
            this.fetchExistingFiles();
        }
    }

    fetchExistingFiles = async () => {
        const newAssets = { ...this.state.assets };
        let operations = [];

        Object.keys(newAssets).map(key => {
            const version = newAssets[key].version;

            return operations = [...operations, ...Object.keys(newAssets[key].files).map(async fileName => {
                try {
                    const fileUrl = await this.assetsRepository.getFile(key, fileName, version);

                    return newAssets[key].files[fileName].hasBeenUploaded = !!fileUrl;
                }
                catch (error) {
                    return newAssets[key].files[fileName].hasBeenUploaded = false;
                }
            })];
        });

        await Promise.all(operations);
        
        this.setState({ assets: newAssets });
    }

    handleFileChange = (key, fileName, file) => {
        const newSelectedFiles = { ...this.state.selectedFiles };

        if (file.name !== fileName) return alert(`File should be named ${fileName}`);

        if (newSelectedFiles[key]) {
            newSelectedFiles[key].files[fileName] = file;
        } else {
            newSelectedFiles[key] = {
                files : {
                    [fileName] : file
                }
            }
        }
        
        this.setState({ selectedFiles: newSelectedFiles });
    }

    handleUploadFiles = async () => {
        const { selectedFiles, assets } = this.state;
        const newAssets = { ...assets };

        if (Object.keys(selectedFiles).length === 0) return;

        let message = 'Upload successful';
        let operations = [];

        this.setState({ uploading: true });

        Object.keys(selectedFiles).map(key => {
            const version = assets[key].version;

            return operations = [...operations, ...Object.keys(selectedFiles[key].files).map(async fileName => {
                try {
                    await this.assetsRepository.saveFile(selectedFiles[key].files[fileName], key, fileName, version);
                    
                    return newAssets[key].files[fileName].hasBeenUploaded = true;
                }
                catch (error) {
                    return message = 'Upload failed';
                }
            })];
        });

        await Promise.all(operations);

        alert(message);

        this.setState({ uploading: false, selectedFiles: {}, assets: newAssets });
    }

    render = () => {
        const { developmentMode } = this.props;
        const { assets, uploading } = this.state;

        return (
            <UploadingAssets
                assets={assets}
                uploading={uploading}
                onFileChange={this.handleFileChange}
                onUploadFiles={this.handleUploadFiles}
                developmentMode={developmentMode}
            />
        );
    }
}

UploadAssets.propTypes = {
    assets          : PropTypes.shape({
        key : PropTypes.exact({
            files : PropTypes.shape({
                fileName : PropTypes.exact({})
            }).isRequired,
            version : PropTypes.number,
            manualUploadPermitted : PropTypes.bool,
            manageLink : PropTypes.string
        })
    }),
    developmentMode : PropTypes.bool
}

UploadAssets.defaultProps = {
    developmentMode : false
}

export default UploadAssets;