import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Home from '../pages/Home';
import Auth from '../Auth';
import Firebase from '../Firebase';
import CookieProvider from '../CookieProvider';
import Base from '../pages/Base';
import Apps from '../pages/Apps';
import ManageStrings from '../pages/ManageStrings';
import ManageRemoteConfig from '../pages/ManageRemoteConfig';
import ManageBuildConfig from '../pages/ManageBuildConfig';
import ManageFonts from '../pages/ManageFonts';
import ManageImages from '../pages/ManageImages';
import ManageApp from '../pages/ManageApp';
import ManageFirebase from '../pages/ManageFirebase';
import ManageSigning from '../pages/ManageSigning';
import Releases from '../pages/Releases';
import ManageRelease from '../pages/ManageRelease';

const firebase = new Firebase();
const db = firebase.db

const authHelper = {
    isLoggedIn() { return CookieProvider.get('uid') !== 'null'; }
}

function LoggedInOutNavElements(props) {
    if(!props.isLoggedIn) {
        return (<Nav.Link href="/login">Login</Nav.Link>)
    }
    return (
        <React.Fragment>
            <Nav.Link href="/base">Base</Nav.Link>
            <Nav.Link href="/apps">Apps</Nav.Link>
            <Nav.Link href="/" onClick={() => {
                firebase.auth.signOut()
                CookieProvider.set('uid', null)
                CookieProvider.set('email', null) }}>
                Logout
            </Nav.Link>
        </React.Fragment>
    )
}

function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={props =>
                authHelper.isLoggedIn() ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    );
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasDeterminedAuthStatus: false, isLoggedIn: false };
    }

    render() {
        var isLoggedIn = this.state.isLoggedIn;
        return (
            <Router>
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand href="/">AssetDelivery</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            
                        </Nav>
                        <Nav>
                            <LoggedInOutNavElements isLoggedIn={isLoggedIn} />
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Container className="mainContainer">
                    <Route exact path="/" component={Home} />
                    <PrivateRoute exact path="/base" component={Base} />
                    <PrivateRoute exact path="/apps" component={Apps} />
                    <PrivateRoute exact path='/base/releases' component={Releases} />
                    <PrivateRoute exact path='/base/releases/:id' component={ManageRelease} />
                    <PrivateRoute exact path="/apps/:appCode" component={ManageApp} />
                    <PrivateRoute exact path="/strings/:appCode/frontier" component={ManageStrings} />
                    <PrivateRoute exact path="/remoteConfig/:appCode/:platform" component={ManageRemoteConfig} />
                    <PrivateRoute exact path="/buildConfig/:appCode/:platform" component={ManageBuildConfig} />
                    <PrivateRoute exact path="/fonts/:appCode/frontier" component={ManageFonts} />
                    <PrivateRoute exact path="/images/:appCode/frontier" component={ManageImages} />
                    <PrivateRoute exact path="/firebase/:appCode/:platform" component={ManageFirebase} />
                    <PrivateRoute exact path='/signing/:appCode/frontier' component={ManageSigning} />
                    <Route exact path="/login" component={Auth} />
                </Container>
            </Router>
        )
    }

    componentDidMount() {
        this.getUser();
    }

    getUser() {
        var self = this;
        firebase.auth.onAuthStateChanged(function(user) {
            if(user) {
                self.setState({ hasDeterminedAuthStatus: true, isLoggedIn: user !== null })
                CookieProvider.set('uid', user.uid);
                CookieProvider.set('email', user.email);
                self.autoCreateUser(user);
            }
        });
    }

    autoCreateUser(user) {
        if (user.displayName) return;
        const displayName = user.email.split("@")[0]
        var userData = {
            displayName: displayName
        }
        db.collection("users").doc(user.uid).set(userData)
        .then(function() {
            return user.updateProfile({
                displayName: displayName,
            })
        });
    }
}

export default App;