import Firebase from '../../components/Firebase';
import AppRepository from '../../repositories/App';
import Util from '../../util';

const firebase = new Firebase();
const db = firebase.db;

class BuildConfigRepository {

    constructor(platform, appCode) {
        this.platform = platform;
        this.appConfigPath = `apps/${appCode}/platforms/${platform}/buildConfig`;
        this.baseConfigPath =  `base/${platform}/buildConfig`;
        this.storageAppPath = `${appCode}/${platform}/buildConfig`;
        this.storageBasePath = `base/${platform}/buildConfig`;
        this.appRepository = new AppRepository(appCode);
    }

    async getCurrentBaseBuildConfig(callback) {
        const resourceVersions = await this.appRepository.getRequiredResourceVersions()
        const version = resourceVersions["buildConfig"]

        db.collection(this.baseConfigPath)
        .doc(String(version))
        .get()
        .then(doc => {
            if(!doc.exists) return callback(null, Error('No configuration available'));
            callback(Util.sortSchema(doc.data()), null);
        })
        .catch(function(error) {
            callback(null, error.message);
        });
    }

    getLatestBuildConfig(isBase, callback) {
        db.collection(isBase? this.baseConfigPath : this.appConfigPath)
        .orderBy("version", "desc")
        .limit(1)
        .get()
        .then((querySnapshot) => {
            let docs = [];

            querySnapshot.forEach(function(doc) {
                docs.push(doc.data());
            });
            if (docs.length === 0) return callback(null, Error('No configuration found'));
            callback(Util.sortSchema(docs[0]), null);
        })
        .catch((error) => {
            callback(null, error);
        })
    }

    getBaseSchemaByVersion = async (version) => {
        try {
            const doc = await db.collection(this.baseConfigPath)
                .doc(String(version))
                .get();

            if (!doc.exists) throw new Error('No config available');

            return Util.sortSchema(doc.data());
        }
        catch (error) {
            throw error;
        }
    }

    saveConfig(isBase, config, callback) {
        db.collection(isBase ? this.baseConfigPath : this.appConfigPath)
        .doc(String(config.version))
        .set(config)
        .then(() => {
            const configJson = JSON.stringify(config);
            const blob = new Blob([ configJson ], { type: 'application/json' });
            const storagePath = `${isBase ? this.storageBasePath : this.storageAppPath}/${config.version}`;
            firebase.storage.child(`${storagePath}/buildConfig.json`).put(blob);
            callback(null);
        })
        .catch((error) => {
            callback(error);
        })
    }
}

export default BuildConfigRepository;