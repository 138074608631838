import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link }         from 'react-router-dom';

class Base extends React.Component {
    render() {
        return (
            <div>
                <h1>Base Management</h1>
                <p>Here you can manage the schemas that all client apps are configured with.</p>
                <ListGroup>
                    <ListGroup.Item action as={Link} to={'base/releases'}>
                        Manage releases
                    </ListGroup.Item>
                    <ListGroup.Item action as={Link} to={'strings/base/frontier'}>
                        Manage strings
                    </ListGroup.Item>
                    <ListGroup.Item action as={Link} to={'remoteConfig/base/frontier'}>
                        Manage remote config
                    </ListGroup.Item>
                    <ListGroup.Item action as={Link} to={'buildConfig/base/frontier'}>
                        Manage build config
                    </ListGroup.Item>
                   <ListGroup.Item action as={Link} to={'fonts/base/frontier'}>
                        Manage fonts
                    </ListGroup.Item>
                    <ListGroup.Item action as={Link} to={'images/base/frontier'}>
                        Manage images
                    </ListGroup.Item>
                </ListGroup>
            </div>
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            apps: [],
            platforms: ["iOS", "Android"]
        }
    }

    componentDidMount() { }
}

export default Base;
