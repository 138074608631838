import React from 'react';

import AddSingleFieldForm from '../forms/AddSingleFieldForm';
import ResourceSection    from '../ResourceSection';
import FontStyleField     from './FontStyleField';

function FontList({
    fontStyles,
    isModificationAllowed,
    onAddFontStyle,
    onDeleteFontStyle,
    onFileNameChange,
    onSizeChange,
    onTooltipChange
}) {
    return (
        <div className='fontList'>
            {isModificationAllowed ?
                <AddSingleFieldForm
                    onAdd={onAddFontStyle}
                    placeholder='New Font Style Key'
                /> :
                null
            }
            <div className='mt-3'>
                {Object.keys(fontStyles).map(fontStyleKey => {
                    return (
                        <ResourceSection
                            key={fontStyleKey}
                            title={fontStyleKey}
                            tooltip={fontStyles[fontStyleKey].tooltip}
                            isEditableTooltip={isModificationAllowed}
                            canBeDeleted={isModificationAllowed}
                            onTooltipChange={onTooltipChange.bind(this, fontStyleKey)}
                            onDelete={onDeleteFontStyle.bind(null, fontStyleKey)}
                            isNew={fontStyles[fontStyleKey].isNew}
                        >
                            <FontStyleField
                                fileName={fontStyles[fontStyleKey].fileName}
                                fontSize={fontStyles[fontStyleKey].size}
                                onFileNameChange={onFileNameChange.bind(null, fontStyleKey)}
                                onSizeChange={onSizeChange.bind(null, fontStyleKey)}
                            />
                        </ResourceSection> 
                    );
                })}
            </div>
        </div>
    );
}

export default FontList;