import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import DeleteButton from '../../buttons/DeleteButton';
import Tooltip      from '../../Tooltip';

import './ImageItem.css';

function ImageItem({
    name,
    displayName,
    parentName,
    resizable,
    imageWidth,
    imageHeight,
    tooltip,
    isModificationAllowed,
    url,
    onDelete,
    onFileChange,
    onResizableChange,
    onTooltipChange
}) {
    function handleFileChange(event) {
        const file = event.target.files[0];

        if (file) {
            onFileChange(parentName, name, file);
        }
    }

    function handleResizableChange(event) {
        const resizable = event.target.checked;

        onResizableChange(parentName, name, resizable);
    }

    const imageClasses = `imageItem${isModificationAllowed ? ' spaceBetween' : ''}`;

    return (
        <div className={imageClasses}>
            <div className='imageItemHeader'>
                <h5>{displayName}</h5>
                {isModificationAllowed &&
                    <DeleteButton onClick={onDelete.bind(this, parentName, name)} />
                }
            </div>
            <div>
                <div className='imageWrapper'>
                    <p className='imageItemSize'>
                        {`${imageWidth || 0}x${imageHeight || 0}px`}
                    </p>
                    <input type='file' id={`${parentName}-${name}-input`} className='imageInput' accept='.png' onChange={handleFileChange}/>
                    <label htmlFor={`${parentName}-${name}-input`} className='imageLabel'>
                        {url ?
                            <img src={url} className='image' alt={displayName} /> :
                            <div className='imageChoose'>Choose image</div>
                        }
                    </label>                
                </div>
                {isModificationAllowed &&
                    <Form.Check
                        inline
                        label    = 'Resizable'
                        checked  = {resizable || false}
                        onChange = {handleResizableChange}
                    />
                }
                <Tooltip
                    value     = {tooltip}
                    editable  = {isModificationAllowed}
                    onChange  = {onTooltipChange.bind(this, parentName, name)}
                    className = 'mt-2'
                />
            </div>
        </div>
    );
}

ImageItem.propTypes = {
    name                  : PropTypes.string.isRequired,
    displayName           : PropTypes.string.isRequired,
    parentName            : PropTypes.string.isRequired,
    resizable             : PropTypes.bool,
    imageWidth            : PropTypes.number,
    imageHeight           : PropTypes.number,
    tooltip               : PropTypes.string,
    isModificationAllowed : PropTypes.bool,
    url                   : PropTypes.string,
    onDelete              : PropTypes.func.isRequired,
    onFileChange          : PropTypes.func.isRequired,
    onResizableChange     : PropTypes.func.isRequired,
    onTooltipChange       : PropTypes.func.isRequired
}

ImageItem.defaultProps = {
    isModificationAllowed : false,
    url                   : ''
}

export default ImageItem;