import React                 from 'react';

import ManageResourcesLayout from '../../layouts/ManageResourcesLayout';
import SaveButton            from '../../buttons/SaveButton';
import StringsList           from '../../StringsList';

import StringsRepository     from '../../../repositories/Strings';
import Util                  from '../../../util';

const BASE_LANGUAGE_CODE = 'Base';

class ManageStrings extends React.Component {
    constructor(props) {
        super(props);
        const { params } = props.match;
        this.appCode = params.appCode;
        this.stringsRepository = new StringsRepository(this.appCode);
        this.baseStrings = {};
        this.state = {
            strings              : {},
            version              : 0,
            baseVersion          : 0,
            loading              : true,
            uploading            : false,
            hasCreatedNewVersion : false
        }
    }

    componentDidMount = async () => {
        await this.fetchStrings();

        if (!this.isBase()) {
            await this.fetchBaseStrings();
        }

        this.setState({ loading: false });
    }

    isBase = () => Util.isBase(this.appCode);

    showMessage = message => alert(message);

    incrementVersionIfNecessary = () => {
        const { hasCreatedNewVersion, version } = this.state;

        if (hasCreatedNewVersion) return;

        alert('This will create a new version 🍄');

        this.setState({ version: version + 1, hasCreatedNewVersion: true });
    }

    fetchStrings = async () => {
        try {
            const schema = await this.stringsRepository.getLatestSchema();

            if (!schema) return this.showMessage('No strings available');

            const strings = Util.sortStrings(schema.values || schema.strings); // Remove schema.strings when all apps are updated to use 'values'

            this.setState({ strings, version: schema.version });
        }
        catch (error) {
            this.showMessage('No strings available');
        }
    }

    fetchBaseStrings = async () => {
        try {
            const { strings, version } = this.state;
            const currentBaseSchema = await this.stringsRepository.getCurrentBaseSchema();

            let baseSchema = currentBaseSchema;

            if (version && (currentBaseSchema.version !== version)) {
                baseSchema = await this.stringsRepository.getBaseSchemaByVersion(version);
            }

            const newStrings = Util.addTooltips(strings, baseSchema.values, currentBaseSchema.values);
            this.baseStrings = Util.sortStrings(currentBaseSchema.values);
            this.setState({ baseVersion: currentBaseSchema.version, strings: newStrings });
        }
        catch (error) {
            this.showMessage('No base strings available');
        }
    }

    handleSave = async () => {
        const newStrings = { ...this.state.strings };
        const version = this.state.version;

        Object.keys(newStrings).forEach(stringKey => {
            const translations = newStrings[stringKey].translations;

            Object.keys(translations).forEach(language => {
                translations[language] = Util.formatString(translations[language]);
            });

            delete newStrings[stringKey].isNew;
        });

        const sortedStrings = Util.sortStrings(newStrings);
        const schema = Util.formatSchema(sortedStrings, version, this.isBase());

        this.setState({ uploading: true, strings: newStrings });

        try {
            await this.stringsRepository.saveSchema(schema);

            this.showMessage('Saved');
            this.setState({ hasCreatedNewVersion: false });
        }
        catch (error) {
            this.showMessage(error.message);
        }

        this.setState({ uploading: false });
    }

    handleUpgrade = () => {
        const { strings, baseVersion } = this.state;
        const newStrings = this.baseStrings;

        Object.keys(newStrings).forEach(stringKey => {
            const stringKeyObj = strings[stringKey];
            const newStringKeyObj = newStrings[stringKey];

            if (stringKeyObj) {
                newStringKeyObj.translations = {
                    ...newStringKeyObj.translations,
                    ...stringKeyObj.translations
                }
            } else {
                newStringKeyObj.isNew = true;
            }
        });

        this.setState({ strings: newStrings, version: baseVersion });
    }

    handleAddLanguage = language => {
        const newStrings = { ...this.state.strings };
        const stringKeys = Object.keys(newStrings);

        if (!language || stringKeys.length === 0) return;

        if (language in newStrings[stringKeys[0]].translations) return this.showMessage(`${language} already exists`);

        this.incrementVersionIfNecessary();

        stringKeys.forEach(stringKey => {
            newStrings[stringKey].translations[language] = newStrings[stringKey].translations[BASE_LANGUAGE_CODE] || '';
        });

        this.setState({ strings: newStrings });        
    }

    handleAddStringKey = stringKey => {
        if (!stringKey) return this.showMessage('Type string key');

        const formattedStringKey = Util.formatKeyName(stringKey);

        const strings = this.state.strings;
        const stringKeys = Object.keys(strings);

        if (stringKeys.includes(formattedStringKey)) return this.showMessage(`${formattedStringKey} already exists`);

        this.incrementVersionIfNecessary();

        const translations = {};

        let languages = [ BASE_LANGUAGE_CODE ];

        if (stringKeys.length > 0) {
            languages = Object.keys(strings[stringKeys[0]].translations);
        }

        languages.forEach(language => translations[language] = '');

        this.setState({
            strings: {
                ...strings,
                [formattedStringKey]: { translations }
            }
        });
    }

    handleDeleteStringKey = stringKey => {
        const newStrings = { ...this.state.strings };

        this.incrementVersionIfNecessary();

        delete newStrings[stringKey];

        this.setState({ strings: newStrings });
    }

    handleTranslationChange = (stringKey, language, translation) => {
        const newStrings = { ...this.state.strings };

        newStrings[stringKey].translations[language] = translation;

        this.setState({ strings: newStrings });
    }

    handleTooltipChange = (stringKey, tooltip) => {
        const newStrings = { ...this.state.strings };

        newStrings[stringKey].tooltip = tooltip;

        this.setState({ strings: newStrings });
    }

    render = () => {
        const { strings, version, baseVersion, loading, uploading } = this.state;
        const title = `${this.isBase() ? '🏡 Base' : this.appCode} Strings (Version ${version})`;
        const button = <SaveButton onClick={this.handleSave} isUploading={uploading} />;
        const isModificationAllowed = this.isBase();
        const isUpgradeAvailable = !this.isBase() && version !== baseVersion;

        return (
            <ManageResourcesLayout
                title={title}
                button={button}
                isUpgrade={isUpgradeAvailable}
                isLoading={loading}
                onUpgrade={this.handleUpgrade}            
            >
                <StringsList
                    strings={strings}
                    isModificationAllowed={isModificationAllowed}
                    onAddLanguage={this.handleAddLanguage}
                    onAddStringKey={this.handleAddStringKey}
                    onDeleteStringKey={this.handleDeleteStringKey}
                    onTranslationChange={this.handleTranslationChange}
                    onTooltipChange={this.handleTooltipChange}
                />                
            </ManageResourcesLayout>
        );
    }
}

export default ManageStrings;