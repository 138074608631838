import React from 'react';

import ManageResourcesLayout from '../../layouts/ManageResourcesLayout';
import SaveButton            from '../../buttons/SaveButton';
import UploadFileBlock       from '../../UploadFileBlock';
import FontList              from '../../FontList';

import FontRepository from '../../../repositories/Fonts';
import Util from '../../../util';

class ManageFonts extends React.Component {
    constructor(props) {
        super(props);
        const { params } = this.props.match;
        this.appCode = params.appCode;
        this.fontRepository = new FontRepository(params.appCode);
        this.state = {
            fontStyles: {},
            version: null,
            baseVersion: null,
            hasCreatedNewVersion: false,
            selectedFile: null,
            loading: true,
            uploading: false
        };
        this.baseFonts = {};
        this.previousVersion = null
    }

    componentDidMount = async () => {
        await this.fetchBaseFontStyleMap();
        await this.fetchClientFontStyleMap();

        this.setState({ loading: false });
    }

    isBase = () => Util.isBase(this.appCode);

    fetchBaseFontStyleMap = async () => {
        if (this.isBase()) {
            try {
                const fonts = await this.fetchFontStyleMap();

                this.previousVersion = fonts.version;
                this.setState({
                    fontStyles: fonts.values,
                    version: fonts.version
                });
            }
            catch (error) {
                alert(error.message);
            }
        } else {
            try {
                const baseFonts = await this.fontRepository.getBaseFontStylesForCurrentFontsVersion();
                
                this.baseFonts = baseFonts.values;
                this.setState({ baseVersion: baseFonts.version });
            }
            catch (error) {
                alert(error.message);
            }
        }
    }

    fetchClientFontStyleMap = async () => {
        if (this.isBase()) return;

        try {
            const fonts = await this.fetchFontStyleMap();
            const fontsWithTooltips = await this.fetchTooltips(fonts.values, fonts.version);

            this.previousVersion = fonts.version;
            this.setState({
                fontStyles: fontsWithTooltips,
                version: fonts.version
            });
        }
        catch (error) {
            alert(error.message);
        }
    }

    fetchTooltips = async (fontStyles, version) => {
        const { baseVersion } = this.state;

        let baseSchemaValues = this.baseFonts;

        if (baseVersion !== version) {
            const baseSchema = await this.fontRepository.getBaseSchemaByVersion(version);
            baseSchemaValues = baseSchema.values;
        }

        const newFontStyles = Util.addTooltips(fontStyles, baseSchemaValues, this.baseFonts);

        return newFontStyles;
    }

    fetchFontStyleMap = async () => {
        try {
            const fonts = await this.fontRepository.getLatestFontStyleMap();

            return fonts;
        }
        catch (error) {
            throw error;
        }
    }

    handleSave = async () => {
        const { fontStyles, version, selectedFile } = this.state;
        const newFontStyles = { ...fontStyles };

        for (const newFont in newFontStyles) {
            if (newFontStyles[newFont].isNew) {
                delete newFontStyles[newFont].isNew
            }
        }

        this.setState({ fontStyles: newFontStyles, uploading: true });

        const fontStylesSchema = Util.formatSchema(newFontStyles, version, this.isBase());

        try {
            await this.fontRepository.saveFontStyles(fontStylesSchema, this.previousVersion, selectedFile);

            this.setState({ hasCreatedNewVersion: false, uploading: false });
            this.previousVersion = fontStylesSchema.version;
            alert('Font styles saved');
        }
        catch (error) {
            this.setState({ uploading: false });

            alert(error.message);
        }
    }

    handleUpgrade = () => {
        const { fontStyles, baseVersion } = this.state;

        for (const fontStyleKey in this.baseFonts) {
            if (fontStyles[fontStyleKey]) {
                const clientFontStyle = fontStyles[fontStyleKey];
                this.baseFonts[fontStyleKey].fileName = clientFontStyle.fileName;
                this.baseFonts[fontStyleKey].size = clientFontStyle.size;
            } else {
                this.baseFonts[fontStyleKey].isNew = true;
            }
        }

        this.setState({ fontStyles: this.baseFonts, version: baseVersion });
    }

    handleNewFontsArchive = event => {
        if ('Archive.zip' !== event.target.files[0].name) return alert('File should be named Archive.zip');

        this.setState({ selectedFile: event.target.files[0] });
    }

    incrementVersionIfNecessary = () => {
        const { hasCreatedNewVersion, version } = this.state;

        if(hasCreatedNewVersion) return;

        alert('This will create a new version 🍄');

        this.setState({ version: version + 1, hasCreatedNewVersion: true });
    }

    handleAddFontStyle = name => {
        if (!name) return;

        const { fontStyles } = this.state;
        const formattedName = Util.formatKeyName(name);

        this.incrementVersionIfNecessary();

        this.setState({
            fontStyles : {
                [formattedName] : {
                    fileName : '',
                    size     : 0
                },
                ...fontStyles
            }
        });
    }

    handleFontFileNameChange = (key, event) => {
        const fileName = event.target.value;
        const newFontStyles = { ...this.state.fontStyles };

        newFontStyles[key].fileName = fileName;

        this.setState({ fontStyles: newFontStyles });
    }

    handleSizeChange = (key, event) => {
        const size = Number(event.target.value);
        const newFontStyles = { ...this.state.fontStyles };

        newFontStyles[key].size = size;

        this.setState({ fontStyles: newFontStyles });
    }

    handleTooltipChange = (key, tooltip) => {
        const newFontStyles = { ...this.state.fontStyles };

        newFontStyles[key].tooltip = tooltip;

        this.setState({ fontStyles: newFontStyles });
    }

    handleDeleteFontStyle = key => {
        this.incrementVersionIfNecessary();

        const newFontStyles = { ...this.state.fontStyles };

        delete newFontStyles[key];

        this.setState({ fontStyles: newFontStyles });
    }

    render = () => {
        const { fontStyles, version, baseVersion, uploading, loading } = this.state;
        const title = `${this.isBase() ? '🏡 Base' : this.appCode} Fonts (Version ${version || 0})`;
        const isUpgradeAvailable = !this.isBase() && version !== baseVersion;
        const isModificationAllowed = this.isBase();

        return (
            <ManageResourcesLayout
                title={title}
                button={<SaveButton isUploading={uploading} onClick={this.handleSave} />}
                isUpgrade={isUpgradeAvailable}
                isLoading={loading}
                onUpgrade={this.handleUpgrade}
            >
                <UploadFileBlock
                    title='Upload the fonts resource files as Archive.zip'
                    onFileInputChange={this.handleNewFontsArchive}
                />
                <FontList
                    fontStyles={fontStyles}
                    isModificationAllowed={isModificationAllowed}
                    onAddFontStyle={this.handleAddFontStyle}
                    onDeleteFontStyle={this.handleDeleteFontStyle}
                    onFileNameChange={this.handleFontFileNameChange}
                    onSizeChange={this.handleSizeChange}
                    onTooltipChange={this.handleTooltipChange}
                />
            </ManageResourcesLayout>
        );
    }
}

export default ManageFonts;