import Firebase from '../../components/Firebase';

const firebase = new Firebase();
const db = firebase.db;
const storage = firebase.storage;

class SigningRepository {
    constructor(appCode) {
        this.appCode = appCode;
        this.dbPath = `apps/${appCode}/platforms`;
        this.storagePath = `${appCode}/frontier/signing`;
    }

    getSha1AndKeyHash = async () => {
        try {
            const doc = await db.collection(this.dbPath)
                .doc('frontier')
                .get();

            if (!doc.exists) return '';

            const docData = doc.data();
            const result={
                sha1:docData['Android_KeyStore_SHA1'],
                keyHash: docData['Android_Facebook_KeyHash']
            }
            return result;
        }
        catch (error) {
            throw error;
        }
    }

    getFCMServerKey = async () => {
        try {
            const doc = await db.collection(this.dbPath)
                .doc('frontier')
                .get();
            
            if (!doc.exists) return '';

            const docData = doc.data();

            return docData['Android_FCM_ServerKey'];
        }
        catch (error) {
            throw error;
        }
    }

    getFileUrl = async fileName => {
        try {
            const fileUrl = await storage.child(this.getFilePath(fileName)).getDownloadURL();

            return fileUrl;
        }
        catch (error) {
            throw error;
        }
    }

    saveFile = async (file, fileName) => {
        try {
            const snapshot = await storage.child(this.getFilePath(fileName)).put(file);
            const fileUrl = snapshot.ref.getDownloadURL();
            return fileUrl;
        }
        catch (error) {
            throw error;
        }
    }

    saveFCMServerKey = async fcmServerKey => {
        try {
            await db.collection(this.dbPath)
                .doc('frontier')
                .set({ Android_FCM_ServerKey: fcmServerKey }, { merge: true });
        }
        catch (error) {
            throw error;
        }
    }

    passDistributionCertificateToBitrise = async () => {
        const passDistributionCertificate = firebase.functions.httpsCallable('passDistributionCertificate');
        try {
            const certificateSlug = await passDistributionCertificate({ appCode: this.appCode });
            return certificateSlug;
        }
        catch (error) {
            throw error;
        }
    }

    triggerCloudRun = async (password, keyAlias, keystorePassword, requireNewKey) => {
        const cloudRun = firebase.functions.httpsCallable('triggerCloudRun');
        const appCode = this.appCode;

        try {
            const result = await cloudRun({ appCode, password, keyAlias, keystorePassword, requireNewKey });

            return result.data;
        }
        catch (error) {
            throw error;
        }

    }

    generateKeystore = async (password, keyAlias, keystorePassword) => {
        try {
            await this.triggerCloudRun(password, keyAlias, keystorePassword, true);

            const data = await this.getSha1AndKeyHash();

            return data;
        } catch (error) {
            throw error;
        }
    }

    generateSha1AndKeyHash = async (password, keyAlias, keystorePassword) => {
        try {
            await this.triggerCloudRun(password, keyAlias, keystorePassword, false);

            const data = await this.getSha1AndKeyHash();

            return data;
        }
        catch (error) {
            throw error;
        }
    }

    getFilePath = fileName => `${this.storagePath}/${fileName}`;
}

export default SigningRepository;
