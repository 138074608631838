import Firebase from '../../components/Firebase';
import ReleasesRepository from '../Releases';

const firebase = new Firebase();
const db = firebase.db;
const releasesRepository = new ReleasesRepository();

class AppRepository {
    constructor(appCode) {
        this.appCode = appCode;
    }

    fetchApps = async () => {
        try {
            const querySnapshot = await db.collection('apps').get();
            const apps = [];

            querySnapshot.forEach(doc => {
                const app = doc.data();
                app.id = doc.id;
                apps.push(app);
            });

            return apps;
        }
        catch (error) {
            throw error;
        }
    }

    createApp = async newAppId => {
        const newApp = {
            appInfo: {
                appCode       : newAppId,
                displayName   : newAppId
            },
            createdAt: new Date()
        }

        try {
            await db.collection('apps').doc(newAppId).set(newApp);

            return newApp;
        }
        catch (error) {
            throw error;
        }
    }

    fetchAppInfo = async () => {
        const document = await db.collection('apps/')
            .doc(this.appCode)
            .get();

        if (!document.exists) throw new Error('Unable to get app info');

        const appInfo = document.data().appInfo || { appCode: this.appCode };

        return appInfo;
    }

    saveAppInfo = async appInfo => {
        try {
            await db.collection('apps/')
                .doc(this.appCode)
                .update({ appInfo });
        }
        catch (error) {
            throw error;
        }
    }

    async getLiveAppsForRelease(releaseId) {
        const querySnapshot = await db.collection('apps').where('release', '==', releaseId).get()

        const apps = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
        }))

        return apps
    }

    async getRequiredResourceVersions() {
        const appInfo = await this.fetchAppInfo()
        const release = await releasesRepository.getRelease(appInfo.release)
        return await releasesRepository.getResourceVersions('iOS', release.branches.iOS)
    }
}

export default AppRepository;