import React     from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup, Button, DropdownButton, Dropdown} from 'react-bootstrap';

class AddSingleFieldForm extends React.Component {
    state = {
        value: ''
    }

    handleValueChange = event => {
        this.setState({ value: event.target.value });
    }

    handleAddField = type => {
        const { value } = this.state;
        const { onAdd } = this.props;

        onAdd(value, type);
        
        this.setState({ value: '' });
    }

    render = () => {
        const { value } = this.state;
        const { placeholder, buttonName, inputTypes } = this.props;

        return (
            <InputGroup className='addSingleFieldForm'>
                <Form.Control
                    type='text'
                    onChange={this.handleValueChange}
                    value={value}
                    placeholder={placeholder}
                />
                <InputGroup.Append>
                    {inputTypes ?
                        <DropdownButton title={buttonName} variant='success' >
                            {inputTypes.map(type => {
                                const displayedType = type[0].toUpperCase() + type.slice(1);
                                
                                return (
                                    <Dropdown.Item
                                        key={type}
                                        onClick={this.handleAddField.bind(this, type)}
                                    >
                                        {displayedType}
                                    </Dropdown.Item>
                                );
                            })}
                        </DropdownButton> :
                        <Button block variant='success' onClick={this.handleAddField}>{buttonName}</Button>
                    }
                </InputGroup.Append>
            </InputGroup>
        );
    }
}

AddSingleFieldForm.propTypes = {
    placeholder : PropTypes.string,
    buttonName  : PropTypes.string,
    inputTypes  : PropTypes.arrayOf(PropTypes.string),
    onAdd       : PropTypes.func.isRequired
}

AddSingleFieldForm.defaultProps = {
    placeholder : 'Type value',
    buttonName  : '+'
}

export default AddSingleFieldForm;