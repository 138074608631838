import React     from 'react';
import PropTypes from 'prop-types';

import DeleteButton from '../buttons/DeleteButton';
import Tooltip      from '../Tooltip';

import './ResourceSection.css';

function ResourceSection({
    title,
    tooltip,
    onTooltipChange,
    isEditableTooltip,
    canBeDeleted,
    onDelete,
    settingsBlock,
    isNew,
    children
}) {
    return (
        <div className={`resourceSection ${isNew ? 'newItem' : ''}`}>
            <div className='resourceSectionHeader'>
                <h4>{title}</h4>
                <div className='resourceSectionSettings'>
                    {settingsBlock}
                    {canBeDeleted ?
                        <DeleteButton onClick={onDelete} /> :
                        null
                    }
                </div>
            </div>
            <Tooltip
                value     = {tooltip}
                editable  = {isEditableTooltip}
                onChange  = {onTooltipChange}
                className = 'mb-2'
            />
            <div className='resourceSectionContent'>
                {children}
            </div>
        </div>
    );
}

ResourceSection.propTypes = {
    title         : PropTypes.string.isRequired,
    canBeDeleted  : PropTypes.bool,
    isNew         : PropTypes.bool,
    onDelete      : PropTypes.func,
    settingsBlock : PropTypes.element
}

export default ResourceSection;