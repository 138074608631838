import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import Util  from '../../../../../util';

function BoolInput({ values, environmentSpecific, onChange }) {
    let inputElements = null;

    if (environmentSpecific) {
        inputElements = Object.keys(Util.ENV_TYPES).map(envKey => {
            const env = Util.ENV_TYPES[envKey];

            return (
                <Row key={envKey}>
                    <Col>
                        <Form.Check
                            inline
                            checked={values[env]}
                            onChange={onChange.bind(null, env)}
                        />
                        <span>{env}</span>
                    </Col>
                </Row>
            );
        });
    } else {
        inputElements = (
            <Form.Check
                inline
                checked={values[Util.ENV_TYPES.production]}
                onChange={onChange.bind(null, Util.ENV_TYPES.production)}
            />
        )
    }

    return inputElements;
}

export default BoolInput;