import React             from 'react';
import { Button, Alert } from 'react-bootstrap';
import { FaCheck }       from 'react-icons/fa';
import PropTypes         from 'prop-types';

class UploadingAsset extends React.Component {
    handleFileChange = event => {
        const files = event.target.files;

        if (files.length === 0) return;
 
        const { onFileChange, keyName, asset } = this.props;
        const file = files[0];

        onFileChange({ keyName, asset, file });
    }

    render = () => {
        const {
            asset,
            keyName,
            displayAssetName,
            hasBeenUploaded,
            version,
            isRequired,
            manualUploadPermitted,
            manageLink,
            developmentMode
        } = this.props;
        const displayName = `${displayAssetName || asset} ${version ? `(Version ${version}) ` : ''}`;

        return (
            <div className='uploadingAsset'>
                {hasBeenUploaded ? 
                    <Alert variant='success'>
                        <FaCheck /> {displayName} is uploaded
                    </Alert> :
                    <Alert variant='info'>
                        {isRequired ?
                            `${displayName} ${developmentMode ? 'to upload' : 'is required'}` :
                            `Upload ${displayName}`    
                        }
                    </Alert>
                }
                {manualUploadPermitted === false ? 
                    <Button href={manageLink}>{`Manage ${keyName}`}</Button> :
                    <input type='file' onChange={this.handleFileChange}/>
                }
            </div>
        );
    }
}

UploadingAsset.propTypes = {
    asset                 : PropTypes.string,
    keyName               : PropTypes.string,
    displayAssetName      : PropTypes.string,
    hasBeenUploaded       : PropTypes.bool,
    version               : PropTypes.number,
    isRequired            : PropTypes.bool,
    manualUploadPermitted : PropTypes.bool,
    manageLink            : PropTypes.string,
    developmentMode       : PropTypes.bool,
    onFileChange          : PropTypes.func.isRequired
}

UploadingAsset.defaultProps = {
    keyName               : '',
    hasBeenUploaded       : false,
    version               : undefined,
    isRequired            : true,
    manualUploadPermitted : true,
    manageLink            : '',
    developmentMode       : false,
}

export default UploadingAsset;