import React              from 'react';
import PropTypes          from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';

class TranslationFields extends React.Component {
    handleTranslationChange = (language, event) => {
        const onTranslationChange = this.props.onTranslationChange;

        onTranslationChange(language, event.target.value);
    }
    
    render = () => {
        const translations = this.props.translations;

        return (
            <Row className='translationFields'>
                {Object.keys(translations).map(language => {
                    return (
                        <Col className='col-12 col-md-6 col-lg-4' key={language}>
                            <Form.Group className='row no-gutters'>
                                <label className='col-sm-2 col-form-label'>
                                    {language}
                                </label>
                                <Col className='col-sm-10'>
                                    <Form.Control
                                        as='textarea'
                                        rows='2'
                                        value={translations[language]} 
                                        onChange={this.handleTranslationChange.bind(null, language)}
                                    />
                                </Col>
                            </Form.Group> 
                        </Col>
                    );
                })}
            </Row>
        );
    }
}

TranslationFields.propTypes = {
    translations        : PropTypes.shape({
        translationCode: PropTypes.string
    }).isRequired,
    onTranslationChange : PropTypes.func.isRequired
}

export default TranslationFields;