import Firebase       from '../../components/Firebase';
import AppRepository  from '../../repositories/App';

import Util from '../../util';

const firebase = new Firebase();
const db = firebase.db;
const storage = firebase.storage;

class StringsRepository {
    constructor(appCode) {
        this.appCode = appCode;
        this.appSchemaPath = `apps/${appCode}/platforms/frontier/strings`;
        this.baseSchemaPath = `${Util.basePath}/frontier/strings`;
        this.schemaPath = Util.isBase(appCode) ? this.baseSchemaPath : this.appSchemaPath;
        this.storagePath = `${appCode}/frontier/strings`;
        this.appRepository = new AppRepository(appCode);
    }

    getCurrentBaseSchema = async () => {
        const resourceVersions = await this.appRepository.getRequiredResourceVersions()
        const version = resourceVersions["strings"]

        const doc = await db.collection(this.baseSchemaPath)
            .doc(String(version))
            .get();

        if (!doc.exists) throw new Error('No schema available');

        return doc.data();
    }

    getLatestSchema = async (isBase) => {
        const schemaPath = isBase ? this.baseSchemaPath : this.schemaPath;
        const querySnapshot = await db.collection(schemaPath)
            .orderBy('version', 'desc')
            .limit(1)
            .get();

        const docData = querySnapshot?.docs?.[0]?.data();

        if (!docData) throw new Error('No schema available');

        return docData;
    }

    getBaseSchemaByVersion = async (version) => {
        const doc = await db.collection(this.baseSchemaPath)
            .doc(String(version))
            .get();

        if (!doc.exists) throw new Error('No schema available');

        return doc.data();
    }

    saveSchema = async schema => {
        await db.collection(this.schemaPath)
            .doc(String(schema.version))
            .set(schema);

        await this.saveSchemaFile(schema);
    }

    saveSchemaFile = async schema => {
        const schemaJson = JSON.stringify(schema);
        const blob = new Blob([ schemaJson ], { type: 'application/json' });
        const storagePath = `${this.storagePath}/${schema.version}`;

        await storage.child(`${storagePath}/strings.json`).put(blob);
    }
}

export default StringsRepository;