import React         from 'react';
import ListGroup     from 'react-bootstrap/ListGroup';

import ConfigItem    from './ConfigItem';
import AddConfigItem from './AddConfigItem';

function ConfigModule({
    moduleName,
    module,
    isModificationAllowed,
    keyTypes,
    onDeleteKey,
    onDeprecateKey,
    onEnvSpecificChange,
    onChangeConfigKeyValue,
    onTooltipChange,
    onAddKey
}) {
    return (
        <ListGroup.Item>
            <h4>{moduleName}</h4>
            {Object.keys(module).map(configKey => {
                const configKeyObj = module[configKey];

                return (
                    <ConfigItem
                        key={moduleName+configKey}
                        name={configKey}
                        parentName={moduleName}
                        tooltip={configKeyObj.tooltip}
                        versionAdded={configKeyObj.versionAdded}
                        versionRemoved={configKeyObj.versionRemoved}
                        type={configKeyObj.type}
                        values={configKeyObj.values}
                        environmentSpecific={configKeyObj.environmentSpecific}
                        canBeOverriden={configKeyObj.canBeOverriden}
                        options={configKeyObj.options}
                        isModificationAllowed={isModificationAllowed}
                        onDeprecateKey={onDeprecateKey}
                        onDeleteKey={onDeleteKey}
                        onEnvSpecificChange={onEnvSpecificChange}
                        onChangeConfigKeyValue={onChangeConfigKeyValue}
                        onTooltipChange={onTooltipChange}
                    />
                );
            })}
            {isModificationAllowed ?
                <AddConfigItem
                    module={moduleName}
                    itemTypes={keyTypes}
                    onAddItem={onAddKey}
                /> :
                null
            }
        </ListGroup.Item>
    );
}

export default ConfigModule;