import React from 'react';
import { Row, Col, Form, Badge } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

import StringInput from './StringInput';
import NumberInput from './NumberInput';
import ColorInput  from './ColorInput';
import BoolInput   from './BoolInput';
import Tooltip     from '../../../Tooltip';

import Util  from '../../../../util';

function ConfigItem({
    name,
    parentName,
    versionAdded,
    versionRemoved,
    type,
    values,
    tooltip,
    environmentSpecific,
    canBeOverriden,
    options,
    isModificationAllowed,
    onDeprecateKey,
    onDeleteKey,
    onEnvSpecificChange,
    onChangeConfigKeyValue,
    onTooltipChange
}) {

    let rightColumnContent = null;

    if (versionAdded !== undefined) {
        if (versionRemoved) {
            rightColumnContent = <Badge variant='warning'>{`Removed v${versionRemoved}`}</Badge>;
        }
    } else if (isModificationAllowed) {
        rightColumnContent = (
            <FaTimes 
                style={{ color: 'red' }}
                className='deleteButton'
                onClick={onDeleteKey.bind(this, parentName, name)}
            />
        );
    }

    function handleInputChange(env, event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        onChangeConfigKeyValue(parentName, name, env, value);
    }

    function handleEnvSpecificChange(event) {
        const isEnvSpecific = event.target.checked;

        onEnvSpecificChange(parentName, name, isEnvSpecific);
    }

    function renderInput() {
        let input = null;

        switch (type) {
            case Util.KEY_TYPES.string : {
                input = <StringInput
                            values={values}
                            environmentSpecific={environmentSpecific}
                            options={options}
                            onChange={handleInputChange}
                        />;
                break;
            }
            case Util.KEY_TYPES.number : {
                input = <NumberInput
                            values={values}
                            environmentSpecific={environmentSpecific}
                            options={options}
                            onChange={handleInputChange}
                        />;
                break;
            }
            case Util.KEY_TYPES.bool : {
                input = <BoolInput
                            values={values}
                            environmentSpecific={environmentSpecific}
                            onChange={handleInputChange}
                        />;
                break;
            }
            case Util.KEY_TYPES.color : {
                input = <ColorInput
                            values={values}
                            environmentSpecific={environmentSpecific}
                            options={options}
                            onChange={handleInputChange}
                        />;
                break;
            }
            default: break;
        }

        return (
            <Row>
                <Col>
                    {input}
                </Col>
            </Row>
        );
    }

    return (
        <div className='key'>
            <Row className='keyHeader'>
                <div className='left'>
                    {isModificationAllowed && versionAdded !== undefined && !versionRemoved ?
                        <FaTimes 
                            style={{ color: 'red' }} 
                            className='deprecateButton' 
                            onClick={onDeprecateKey.bind(null, parentName, name)}
                        /> :
                        null
                    }
                    <h5 className='name'>{name}</h5>
                </div>
                <div className='right'>
                    { isModificationAllowed ? 
                        <div className='flagsWrapper'>
                            {canBeOverriden !== undefined ?
                                <div>
                                    <span className='checkboxLabel'>Overridable</span>
                                    <Form.Check
                                        inline
                                        checked={canBeOverriden}
                                    />
                                </div> :
                                null
                            }
                            <div>
                                <span className='checkboxLabel'>Env Specific</span>
                                <Form.Check
                                    inline
                                    checked={environmentSpecific}
                                    onChange={handleEnvSpecificChange}
                                />
                            </div>
                        </div> :
                        null
                    }
                    {rightColumnContent}
                </div>
            </Row>
            <Tooltip
                value     = {tooltip}
                editable  = {isModificationAllowed}
                onChange  = {onTooltipChange.bind(this, parentName, name)}
                className = 'mb-2'
            />
            <Row>
                <Col>
                    {renderInput()}
                </Col>
            </Row>
        </div>
    )
}

export default ConfigItem;