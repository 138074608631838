import AppRepository from '../repositories/App';

export const FETCH_APPS_SUCCESS = 'FETCH_APPS_SUCCESS';
export const SET_APP            = 'SET_APP';
export const ADD_NEW_APP        = 'ADD_NEW_APP';

const appRepository = new AppRepository();

export function fetchApps() {
    return async dispatch => {
        try {
            const apps = await appRepository.fetchApps();

            dispatch({
                type    : FETCH_APPS_SUCCESS,
                payload : apps
            });
        } catch (error) {
            throw error;
        }
    }
}

export function setApp(app) {
    return async dispatch => {
        dispatch({
            type    : SET_APP,
            payload : app
        });
    }
}

export function addNewApp(newAppId) {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const { apps } = state.apps;
            const newApp = await appRepository.createApp(newAppId);

            newApp.id = newAppId;
            apps.push(newApp);

            dispatch({
                type    : ADD_NEW_APP,
                payload : apps
            });
        } catch (error) {
            throw error;
        }
    }
}