import React, { useEffect, useState }   from 'react';
import { useParams }                    from 'react-router-dom';
import {
    Row,
    Col,
    Button,
    Form,
    Dropdown,
    DropdownButton
}                                       from 'react-bootstrap';
import ReleasesRepository               from '../../../repositories/Releases';
import AppRepository               from '../../../repositories/App';

const RELEASE_STATUSES = ['draft', 'ready', 'declined']
const releasesRepository = new ReleasesRepository()
const appRepository = new AppRepository()

function ManageReleases() {

    const { id: initialReleaseId } = useParams()
    const [isNewRelease, setIsNewRelease] = useState(initialReleaseId === 'new')
    const [release, setRelease] = useState({
        id: '',
        status: 'draft',
        branches: {
            iOS: '',
            Android: ''
        },
        releaseNotes: '',
    })
    const [iOSResourceVersions, setiOSResourceVersions] = useState({})
    const [androidResourceVersions, setAndroidResourceVersions] = useState({})
    const [liveApps, setLiveApps] = useState([])

    useEffect(() => {
        const getRelease = async () => {
            try {
                const release = await releasesRepository.getRelease(initialReleaseId)
                setRelease(release)
            } catch (error) {
                alert(error.message)
            }
        }
        if(initialReleaseId !== 'new') getRelease()
    }, [initialReleaseId])

    useEffect(() => {
        getResourceVersions('iOS', release.branches.iOS)
        getResourceVersions('Android', release.branches.Android)
    }, [release.branches])

    useEffect(() => {
        const getLiveAppsForRelease = async (releaseId) => {
            try {
                const fetchedLiveApps = await appRepository.getLiveAppsForRelease(releaseId)
                setLiveApps(fetchedLiveApps)
            } catch(error) {
                console.log(error.message)
            }
        }
        if(release.status === 'ready') getLiveAppsForRelease(release.id)
    }, [release.id, release.status])

    const getResourceVersions = async (platform, branch) => {
        const performUpdate = (platform, newResourceVersions) => {
            if(platform === 'iOS') {
                setiOSResourceVersions(newResourceVersions)
            } else if(platform === 'Android') {
                setAndroidResourceVersions(newResourceVersions)
            }
        }
        performUpdate(platform, {})
        if(branch.length < 5) return
        try {
            const resourceVersions = await releasesRepository.getResourceVersions(platform, branch)
            performUpdate(platform, resourceVersions)
        } catch(error) {
            console.log(error.message)
        }
    }

    const updateRelease = (e) => {
        const { id, value, isBranch } = e.target
        if(isBranch) {
            setRelease(prevState => ({
                ...prevState,
                branches: {
                    ...prevState.branches,
                    [id]: value
                }
            }))
            getResourceVersions(id, value)
        } else {
            setRelease(prevState => ({
                ...prevState,
                [id]: value
            }))
        }
    }

    const saveRelease = async () => {
        try {
            const newRelease = await (isNewRelease ? releasesRepository.createRelease(release) : releasesRepository.updateRelease(release))
            setRelease(newRelease)
            setIsNewRelease(false)
            alert("Release saved")
        } catch(error) {
            alert(error.message)
        }
    }

    const resourceVersionsAreAligned = () => {
        if(!iOSResourceVersions || Object.keys(iOSResourceVersions).length === 0) return false
        if(!androidResourceVersions || Object.keys(androidResourceVersions).length === 0) return false
        const allKeysMatch = Object.keys(iOSResourceVersions).every((k) => iOSResourceVersions[k] === androidResourceVersions[k])
        return allKeysMatch
    }

    return(
        <div className='manageReleasesContainer'>
            <Row>
                <Col>
                    <h1>{(isNewRelease ? 'New' : 'Edit')+' Release'}</h1>
                </Col>
                <Col>
                    <Button variant='success' className='float-right' onClick={saveRelease} disabled={!resourceVersionsAreAligned()}>{isNewRelease ? 'Create' : 'Update'}</Button>
                </Col>
            </Row>
            <Form>
                <Row>
                    <Col xs={12}>
                        <h4>Basic Info</h4>
                    </Col>
                    <Col>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm='2'>
                                <b>Version:</b>
                            </Form.Label>
                            <Col sm='10'>
                                <Form.Control id='id' type='text' disabled={!isNewRelease} value={release.id} onChange={updateRelease}/>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm='2'>
                                <b>Status:</b>
                            </Form.Label>
                            <DropdownButton title={release.status.charAt(0).toUpperCase() + release.status.slice(1)} variant='primary'>
                                {RELEASE_STATUSES.map(releaseStatus => {
                                    return (
                                        <Dropdown.Item
                                            key={releaseStatus}
                                            onClick={() => updateRelease({target: {id: 'status', value: releaseStatus}})}
                                        >
                                            {releaseStatus.charAt(0).toUpperCase() + releaseStatus.slice(1)}
                                        </Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h4>Branches & Resource Versions</h4>
                    </Col>
                    <Col>
                        <h5 className='text-center'>iOS</h5>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm='2'>
                                <b>Branch:</b>
                            </Form.Label>
                            <Col sm='10'>
                                <Form.Control id='id' type='text' value={release.branches.iOS} onChange={(e) => updateRelease({target: {isBranch: true, id: 'iOS', value: e.target.value}})}/>
                            </Col>
                        </Form.Group>
                        {Object.keys(iOSResourceVersions).map((k) =>
                            <p key={'iOS-'+k}>
                                <b>{k}</b>: {iOSResourceVersions[k]} { iOSResourceVersions[k] !== androidResourceVersions[k] ? '⛔️' : ''}
                            </p>
                        )}
                    </Col>
                    <Col>
                        <h5 className='text-center'>Android</h5>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm='2'>
                                <b>Branch:</b>
                            </Form.Label>
                            <Col sm='10'>
                                <Form.Control id='id' type='text' value={release.branches.Android} onChange={(e) => updateRelease({target: {isBranch: true, id: 'Android', value: e.target.value}})}/>
                            </Col>
                        </Form.Group>
                        {Object.keys(androidResourceVersions).map((k) =>
                            <p key={'iOS-'+k}>
                                <b>{k}</b>: {androidResourceVersions[k]} { androidResourceVersions[k] !== iOSResourceVersions[k] ? '⛔️' : ''}
                            </p>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>Release Notes & Known Issues</h4>
                        <Form.Control
                            as='textarea'
                            id='releaseNotes'
                            rows='4'
                            value={release.releaseNotes} 
                            onChange={updateRelease}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>Live Apps</h4>
                        { isNewRelease || release.status !== 'ready' ? 
                            <p>This release is not yet live</p>
                        :
                            liveApps.map((app) => {
                                return <p key={app.id}>{app.appInfo.displayName}</p>
                            })
                        }
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ManageReleases;