import React            from 'react';
import { connect }      from 'react-redux';
import PropTypes        from 'prop-types';
import {
    Row,
    Col,
    Tab,
    ListGroup,
    Button, Form,
    InputGroup
}                       from 'react-bootstrap';
import { Link }         from 'react-router-dom';

import * as AppsActions from '../../../actions/AppsActions';

class Apps extends React.Component {
    static propTypes = {
        apps      : PropTypes.array,
        app       : PropTypes.object,
        fetchApps : PropTypes.func.isRequired,
        setApp    : PropTypes.func.isRequired,
        addNewApp : PropTypes.func.isRequired
    }

    state = {
        newAppId : ''
    }

    componentDidMount = async () => {
        const { fetchApps, apps } = this.props;

        if (apps.length) return;

        try {
            await fetchApps();
        }
        catch (error) {
            alert(error.message);
        }
    }

    updateNewAppId = event => {
        this.setState({ newAppId: event.target.value });
    }

    addNewApp = async () => {
        const { newAppId } = this.state;
        const { addNewApp } = this.props;

        if (!newAppId) return alert('Invalid app ID');
        if (newAppId !== newAppId.toUpperCase()) {
            return alert('App Code must be in uppercase');
        }

        try {
            await addNewApp(newAppId);

            this.setState({ newAppId: '' });
        }
        catch (error) {
            alert(error.message);
        }
    }

    handleAppSelect = appId => {
        const { apps, setApp } = this.props;
        const app = apps.find(app => app.id === appId);

        setApp(app);       
    }

    render = () => {
        const { apps, app } = this.props;
        const { newAppId } = this.state;

        return (
            <div>
                <h1>Apps</h1>
                <p>Here you can manage client apps derived from the base schemas.</p>
                <Tab.Container activeKey={app?.id} onSelect={this.handleAppSelect}>
                    <Row>
                        <Col sm={3}>
                            <ListGroup>
                                {apps.map(app =>
                                    <ListGroup.Item key={app.id} href={app.id}>{app.appInfo?.displayName || app.id}</ListGroup.Item>
                                )}
                                <ListGroup.Item>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            onChange={this.updateNewAppId}
                                            value={newAppId}
                                            placeholder="App code"
                                        />
                                        <InputGroup.Append>
                                            <Button variant="success" onClick={this.addNewApp}>+</Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                {apps.map(app =>
                                    <Tab.Pane key={app.id} eventKey={app.id}>
                                        <h4>{app.appInfo?.displayName || app.id} ({app.id})</h4>
                                        <ListGroup>
                                            <ListGroup.Item action as={Link} to={`apps/${app.id}`}>
                                                Manage App
                                            </ListGroup.Item>
                                            <ListGroup.Item action as={Link} to={`strings/${app.id}/frontier`}>
                                                Manage Strings
                                            </ListGroup.Item>
                                            <ListGroup.Item action as={Link} to={`remoteConfig/${app.id}/frontier`}>
                                                Manage Remote Config
                                            </ListGroup.Item>
                                            <ListGroup.Item action as={Link} to={`buildConfig/${app.id}/frontier`}>
                                                Manage Build Config
                                            </ListGroup.Item>
                                            <ListGroup.Item action as={Link} to={`fonts/${app.id}/frontier`}>
                                                Manage Fonts
                                            </ListGroup.Item>
                                            <ListGroup.Item action as={Link} to={`images/${app.id}/frontier`}>
                                                Manage Images
                                            </ListGroup.Item>
                                            <ListGroup.Item action as={Link} to={`firebase/${app.id}/frontier`}>
                                                Manage Firebase
                                            </ListGroup.Item>
                                            <ListGroup.Item action as={Link} to={`signing/${app.id}/frontier`}>
                                                Manage Signing
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Tab.Pane>
                                )}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        );
    }
}

function mapStateToProps({ apps }) {
    return {
        apps : apps.apps,
        app  : apps.app
    };
}

export default connect(mapStateToProps, { ...AppsActions })(Apps);
