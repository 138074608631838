import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaTimes } from 'react-icons/fa';
import BuildConfigStore from './BuildConfigStore';
import Tooltip from '../../Tooltip';

import Util from '../../../util.js';

class ManageBuildConfig extends React.Component {

    constructor(props) {
        super(props);
        const { params } = this.props.match;
        this.buildConfigStore = new BuildConfigStore(params.platform, params.appCode);
        this.newConfigInputs = {};
        this.appCode = params.appCode;
        this.state = {
            config     : {
                values  : {},
                version : 0
            },
            baseConfig : {
                values  : {},
                version : null
            },
            newModule  : ''
        };
    }

    componentDidMount = () => {
        this.buildConfigStore.on(this.buildConfigStore.changeActionKey, (params) => {
            this.setState({ ...params });
        })
        this.buildConfigStore.on(this.buildConfigStore.messageActionKey, () => {
            alert(this.buildConfigStore.message);
        })
        this.buildConfigStore.fetchConfig();
    }

    isBase = () => {
        const { params } = this.props.match;

        return Util.isBase(params.appCode);
    }

    save = () => {
        this.buildConfigStore.save();
    }

    deleteModule = module => {
        this.buildConfigStore.deleteModule(module);
    }

    updateNewModule = event => {
        this.setState({ newModule: event.target.value});
    }

    addModule = () => {
        const { newModule } = this.state;

        if (newModule.length < 1) return;
        this.buildConfigStore.addModule(newModule);
        this.setState({ newModule: '' });
    }

    addNewConfigKey = (module, type, value) => {
        let newConfigKey = this.newConfigInputs[module].value;

        if (this.newConfigInputs[module].value < 1) return;

        this.buildConfigStore.addNewConfigKey(module, newConfigKey, type, value);
        this.newConfigInputs[module].value = '';
    }

    deleteConfigKey = (module, configKey) => {
        this.buildConfigStore.deleteConfigKey(module, configKey);
    }

    updateConfigValue = (module, configKey, environment, event) => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

        this.buildConfigStore.updateConfigValue(module, configKey, environment, value);
    }

    updateTooltip = (module, configKey, tooltip) => {
        this.buildConfigStore.updateTooltip(module, configKey, tooltip);
    }

    updateFlag = (module, configKey, flag, event) => {
        const value = event.target.checked;

        this.buildConfigStore.updateFlag(module, configKey, flag, value);
    }

    performSynchronisation = () => {
        try {
            this.buildConfigStore.performSynchronisation();
        } 
        catch (error) {
            alert(error.message);
        }
    }

    renderHeader = () => {
        const { config } = this.state;

        return (
            <Row className='header'>
                <Col>
                    <h1>
                        {this.isBase() ? "🏡 Base " : this.appCode + " "} 
                        Build Config (Version {config.version ? config.version : 0}) 
                    </h1>
                    <a href="https://livestyled.atlassian.net/wiki/spaces/1LP/pages/3468656651/Build+Config" target="_blank" rel="noopener noreferrer">Help</a>
                </Col>
                <Col sm={1} xs={4} className='saveWrapper'>
                    <Button
                        variant="success"
                        onClick={this.save}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        );
    }

    renderUpgrade = () => {
        const { config, baseConfig } = this.state;

        if (!this.isBase() && config.version !== baseConfig.version) {
            return (
                <Alert variant="primary">
                    There is an upgrade available 
                    <Button onClick={this.performSynchronisation} className="ml-2">Upgrade</Button>
                </Alert>
            );
        }
    }

    renderModule = module => {
        const { config } = this.state;
        const configKeyElements = [];

        for (const configKey in config.values[module]) {
            const configKeyElement = this.renderConfigKey(module, configKey);

            if (configKeyElement) configKeyElements.push(configKeyElement);
        }
        if (!this.isBase() && configKeyElements.length === 0) return;

        return (
            <ListGroup.Item key={module}>
                <h4>
                    {module}
                    { this.isBase() ? <FaTimes style={{color: "red"}} className="float-right" onClick={this.deleteModule.bind(this, module)}></FaTimes> : null }
                </h4>
                {configKeyElements}
                {this.isBase() ? this.renderAddConfigKey(module) : null}
            </ListGroup.Item>
        );
    }

    renderAddModule = () => {
        const { newModule } = this.state;

        return (
            <ListGroup.Item>
                <h4>{newModule}</h4>
                <Row>
                    <Col sm={{ span: 6, offset: 3 }}>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                onChange={this.updateNewModule}
                                value={newModule}
                                placeholder="New section"
                            />
                            <InputGroup.Append>
                                <Button block variant="success" onClick={this.addModule}>+</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
            </ListGroup.Item>
        );
    }

    renderConfigKey = (module, configKey) => {
        const { config }          = this.state;
        const configKeyObj        = config.values[module][configKey];
        const environmentSpecific = configKeyObj.environmentSpecific;
        const canBeOverriden      = configKeyObj.canBeOverriden;
        const tooltip             = configKeyObj.tooltip;

        if (!this.isBase() && !canBeOverriden) return; 

        return (
            <Form className='key' key={module + configKey} >
                <Row>
                    <Col md sm={12}>
                        <h5>{configKey}</h5>
                    </Col>
                    { this.isBase() ? 
                        <Col md={6} sm xs className='flagsWrapper'>
                            <div>
                                <span className='checkboxLabel'>Overridable</span>
                                <Form.Check
                                    inline
                                    checked={canBeOverriden}
                                    onChange={this.updateFlag.bind(this, module, configKey, 'canBeOverriden')}
                                />
                            </div>
                            <div>
                                <span className='checkboxLabel'>Env Specific</span>
                                <Form.Check
                                    inline
                                    checked={environmentSpecific}
                                    onChange={this.updateFlag.bind(this, module, configKey, 'environmentSpecific')}
                                />
                            </div>
                        </Col> :
                        null
                    }
                    <Col sm={1} xs={1}>
                        {this.generateMeta(module, configKey)}
                    </Col>
                </Row>
                <Tooltip
                    value     = {tooltip}
                    editable  = {this.isBase()}
                    onChange  = {this.updateTooltip.bind(this, module, configKey)}
                    className = 'mb-2'
                />
                <Row>
                    <Col>
                        {this.generateInput(module, configKey, configKeyObj)}
                    </Col>
                </Row>
            </Form>
        );
    }

    renderAddConfigKey = module => {
        return (
            <Row className="newKeyContainer">
                <Col sm={{ span: 6, offset: 3 }}>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            ref={elem => this.newConfigInputs[module] = elem}
                            placeholder="New key"
                        />
                        <InputGroup.Append>
                            <DropdownButton title="+" variant="success" className="float-right">
                                <Dropdown.Item onClick={this.addNewConfigKey.bind(this, module, Util.KEY_TYPES.string, '')}>String</Dropdown.Item>
                                <Dropdown.Item onClick={this.addNewConfigKey.bind(this, module, Util.KEY_TYPES.color, '#FFFFFF')}>Color</Dropdown.Item>
                                <Dropdown.Item onClick={this.addNewConfigKey.bind(this, module, Util.KEY_TYPES.bool, false)}>Bool</Dropdown.Item>
                                <Dropdown.Item onClick={this.addNewConfigKey.bind(this, module, Util.KEY_TYPES.number, false)}>Number</Dropdown.Item>
                            </DropdownButton>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
        );
    }

    generateInput = (module, configKey, configKeyObj) => {
        const { type, values, environmentSpecific } = configKeyObj;

        const generateInputsByEnv = () => {
            return Object.keys(Util.ENV_TYPES).map(envKey => {
                const env = Util.ENV_TYPES[envKey];
                let inputElement = null;

                switch (type) {
                    case Util.KEY_TYPES.string : {
                        inputElement = (
                            <>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        value={values[env]}
                                        onChange={this.updateConfigValue.bind(this, module, configKey, env)}
                                    />
                                </Col>
                                <Col sm={3}>
                                    <span>{env}</span>
                                </Col>
                            </>
                        );
                        break;
                    }
                    case Util.KEY_TYPES.color : {
                        inputElement = (
                            <>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        value={values[env]}
                                        onChange={this.updateConfigValue.bind(this, module, configKey, env)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="color"
                                        value={values[env]}
                                        onChange={this.updateConfigValue.bind(this, module, configKey, env)}
                                    />
                                </Col>
                                <Col sm={3}>
                                    <span>{env}</span>
                                </Col>
                            </>
                        );
                        break;
                    }
                    case Util.KEY_TYPES.bool : {
                        inputElement = (
                            <Col>
                                <Form.Check
                                    inline
                                    checked={values[env]}
                                    onChange={this.updateConfigValue.bind(this, module, configKey, env)}
                                />
                                <span>{env}</span>
                            </Col>
                        );
                        break;
                    }
                    case Util.KEY_TYPES.number : {
                        inputElement = (
                            <Col>
                                <Form.Control
                                    type='number'
                                    value={values[env]}
                                    onChange={this.updateConfigValue.bind(this, module, configKey, env)}
                                />
                                <span>{env}</span>
                            </Col>
                        );
                        break;
                    }
                    default : break;
                }

                return (
                    <Row key={envKey}>
                        {inputElement}
                    </Row>
                );
            })
        }

        const generateSingleInput = () => {
            switch (type) {
                case Util.KEY_TYPES.string : {
                    return (
                        <Form.Control
                            type="text"
                            value={values[Util.ENV_TYPES.production]}
                            onChange={this.updateConfigValue.bind(this, module, configKey, Util.ENV_TYPES.production)}
                        />
                    );
                }
                case Util.KEY_TYPES.color : {
                    return (
                        <Row>
                            <Col>
                                <Form.Control
                                    type="text"
                                    value={values[Util.ENV_TYPES.production]}
                                    onChange={this.updateConfigValue.bind(this, module, configKey, Util.ENV_TYPES.production)}
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    type="color"
                                    value={values[Util.ENV_TYPES.production]}
                                    onChange={this.updateConfigValue.bind(this, module, configKey, Util.ENV_TYPES.production)}
                                />
                            </Col>
                        </Row>
                    );
                }
                case Util.KEY_TYPES.bool : {
                    return (
                        <Form.Check
                            inline
                            checked={values[Util.ENV_TYPES.production]}
                            onChange={this.updateConfigValue.bind(this, module, configKey, Util.ENV_TYPES.production)}
                        />
                    );
                }
                case Util.KEY_TYPES.number : {
                    return (
                        <Form.Control
                            type='number'
                            value={values[Util.ENV_TYPES.production]}
                            onChange={this.updateConfigValue.bind(this, module, configKey, Util.ENV_TYPES.production)}
                        />
                    );
                }
                default : return;
            }
        }

        return (
            <Row>
                <Col>
                    {environmentSpecific ?
                        generateInputsByEnv() :
                        generateSingleInput()
                    }
                </Col>
            </Row>
        );
    }

    generateMeta = (module, configKey) => {
        if (this.isBase()) {
            return (
                <FaTimes 
                    style={{ color: 'red' }} 
                    className='float-right' 
                    onClick={this.deleteConfigKey.bind(this, module, configKey)}
                />
            )
        }
    }

    render() {
        const { config } = this.state;

        return (
            <Row className="buildConfigContainer">
                <Col lg={{ offset: 2, span: 8 }} md={{ offset: 1, span: 10 }} sm={12}>
                    {this.renderHeader()}
                    {this.renderUpgrade()}
                    <ListGroup>
                        {Object.keys(config.values).map((module) => this.renderModule(module))}
                        {this.isBase() ? this.renderAddModule() : null}
                    </ListGroup>
                </Col>
            </Row>
        );
    }
}

export default ManageBuildConfig;