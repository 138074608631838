import React     from 'react';
import PropTypes from 'prop-types';
import ListGroup from 'react-bootstrap/ListGroup';

import ResourceSection      from '../ResourceSection';
import ImageItem            from './ImageItem';
import AddMultipleFieldForm from '../forms/AddMultipleFieldForm';

import './ImageSectionList.css';

class ImageSectionList extends React.Component {
    handleImageFilesChange = event => {
        const { onImageFilesChange } = this.props;
        const files = event.target.files;
    
        onImageFilesChange(files);
    }

    handleAddSection = values => {
        const { onAddSection } = this.props;

        onAddSection(values.sectionName, values.sectionDisplayName);
    }

    handleAddImage = (section, values) => {
        const { onAddItem } = this.props;

        onAddItem(section, values.imageName, values.imageDisplayName);
    }

    render = () => {
        const {
            schema,
            uploadedImages,
            selectedImages,
            isModificationAllowed,
            onImageChange,
            onDeleteSection,
            onDeleteImage,
            onResizableChange,
            onTooltipChange
        } = this.props;

        return (
            <div className='imageSectionList'>
                <div className='imagesInputWrapper'>
                    <input type='file' multiple accept='.png' onChange={this.handleImageFilesChange}/>
                </div>
                <ListGroup>
                    {Object.keys(schema.values).map(section => {
                        const sectionObj = schema.values[section];
    
                        return (
                            <ResourceSection
                                key={section}
                                title={sectionObj.displayName}
                                canBeDeleted={isModificationAllowed}
                                onDelete={onDeleteSection.bind(this, section)}
                            >
                                <div className='imagesWrapper'>
                                    {Object.keys(sectionObj.values).map(item => {
                                        const itemObj = sectionObj.values[item];

                                        return (
                                            <ImageItem
                                                key={item}
                                                name={item}
                                                displayName={itemObj.displayName}
                                                parentName={section}
                                                resizable={itemObj.resizable}
                                                imageWidth={itemObj.width}
                                                imageHeight={itemObj.height}
                                                tooltip={itemObj.tooltip}
                                                isModificationAllowed={isModificationAllowed}
                                                url={selectedImages?.[section]?.[item]?.url || uploadedImages?.[section]?.[item]}
                                                onDelete={onDeleteImage}
                                                onFileChange={onImageChange}
                                                onResizableChange={onResizableChange}
                                                onTooltipChange={onTooltipChange}
                                            />
                                        );
                                    })}
                                    {isModificationAllowed &&
                                        <AddMultipleFieldForm
                                            fields={[
                                                { name: 'imageName', placeholder: 'Image name' },
                                                { name: 'imageDisplayName', placeholder: 'Image display name' }
                                            ]}
                                            onAdd={this.handleAddImage.bind(this, section)}
                                            className='block addImageForm'
                                        />
                                    }                                    
                                </div>
                            </ResourceSection>
                        );
                    })}
                    {isModificationAllowed &&
                        <ListGroup.Item>
                            <AddMultipleFieldForm
                                fields={[
                                    { name: 'sectionName', placeholder: 'Type section name' },
                                    { name: 'sectionDisplayName', placeholder: 'Type section display name' }
                                ]}
                                onAdd={this.handleAddSection}
                                className='block addSectionForm'
                            />
                        </ListGroup.Item>
                    }
                </ListGroup>
            </div>
        )
    }
}

ImageSectionList.propTypes = {
    schema                : PropTypes.exact({
        values : PropTypes.shape({
            section : PropTypes.exact({
                displayName : PropTypes.string.isRequired,
                values      : PropTypes.shape({
                    image : PropTypes.exact({
                        displayName : PropTypes.string.isRequired,
                        resizable   : PropTypes.bool,
                        width       : PropTypes.number,
                        height      : PropTypes.number
                    })
                }).isRequired
            })
        }),
        version : PropTypes.number
    }).isRequired,
    uploadedImages        : PropTypes.object,
    selectedImages        : PropTypes.object,
    isModificationAllowed : PropTypes.bool,
    onImageChange         : PropTypes.func.isRequired,
    onImageFilesChange    : PropTypes.func.isRequired,
    onDeleteSection       : PropTypes.func.isRequired,
    onDeleteImage         : PropTypes.func.isRequired,
    onAddSection          : PropTypes.func.isRequired,
    onAddItem             : PropTypes.func.isRequired,
    onResizableChange     : PropTypes.func.isRequired,
    onTooltipChange       : PropTypes.func.isRequired
}

ImageSectionList.defaultProps = {
    uploadedImages        : {},
    selectedImages        : {},
    isModificationAllowed : false
}

export default ImageSectionList;