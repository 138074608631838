import React                from 'react';
import PropTypes            from 'prop-types';

import AddSingleFieldForm   from '../forms/AddSingleFieldForm';
import ResourceSection      from '../ResourceSection';
import TranslationFields    from './TranslationFields';

import './StringsList.css';

class StringsList extends React.Component {
    render = () => {
        const {
            strings,
            isModificationAllowed,
            onAddLanguage,
            onAddStringKey,
            onDeleteStringKey,
            onTranslationChange,
            onTooltipChange
        } = this.props;

        return (
            <div className='stringsList'>
                {isModificationAllowed &&
                    <div>
                        <AddSingleFieldForm placeholder='New language' onAdd={onAddLanguage} />
                        <AddSingleFieldForm placeholder='New string key' onAdd={onAddStringKey} />
                    </div>
                }
                {Object.keys(strings).map(stringKey => {
                    const translations = strings[stringKey].translations;

                    return (
                        <ResourceSection
                            key={stringKey}
                            title={stringKey}
                            tooltip={strings[stringKey].tooltip}
                            isEditableTooltip={isModificationAllowed}
                            canBeDeleted={isModificationAllowed}
                            onDelete={onDeleteStringKey.bind(null, stringKey)}
                            onTooltipChange={onTooltipChange.bind(this, stringKey)}
                            isNew={strings[stringKey].isNew}
                        >
                            <TranslationFields
                                translations={translations}
                                onTranslationChange={onTranslationChange.bind(null, stringKey)}
                            />
                        </ResourceSection>
                    );
                })}
            </div>
        );
    }
}

StringsList.propTypes = {
    strings               : PropTypes.shape({
        stringKey: PropTypes.exact({
            translations: PropTypes.shape({
                translationCode: PropTypes.string
            }).isRequired,
            isNew: PropTypes.bool
        })
    }).isRequired,
    isModificationAllowed : PropTypes.bool,
    onAddLanguage         : PropTypes.func.isRequired,
    onAddStringKey        : PropTypes.func.isRequired,
    onDeleteStringKey     : PropTypes.func.isRequired,
    onTranslationChange   : PropTypes.func.isRequired,
    onTooltipChange       : PropTypes.func.isRequired
}

export default StringsList;