import React               from 'react';
import PropTypes           from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';

function SaveButton({ onClick, isUploading }) {
    return (
        <Button variant='success' onClick={onClick} disabled={isUploading} >
            {isUploading ?
                <span>
                    <Spinner animation='border' variant='light' size='sm' className='mr-2' />
                    Uploading
                </span> :
                'Save'
            }
        </Button>
    );
}

SaveButton.propTypes = {
    onClick     : PropTypes.func.isRequired,
    isUploading : PropTypes.bool
}

export default SaveButton;