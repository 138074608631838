import React from 'react';

import ConfigModuleList from '../../ConfigModuleList';
import RemoteConfigRepository from '../../../repositories/RemoteConfig';

import Util  from '../../../util';

class ManageRemoteConfig extends React.Component {
    constructor(props) {
        super(props);
        const { params } = this.props.match;        
        this.configRepository = new RemoteConfigRepository(params.platform, params.appCode);
        this.appCode = params.appCode;
        this.state = {
            config: {
                values: { },
                version: null
            },
            baseConfig: {
                values: { },
                version: null
            },
            hasCreatedNewVersion: false
        }
    }

    componentDidMount = () => {
        this.fetchConfig();
    }

    showMessage = message => {
        alert(message);
    }
    
    isBase = () => Util.isBase(this.appCode);

    fetchConfig = () => {
        this.configRepository.getCurrentRemoteConfig(this.isBase(), (result, error) => {
            if (error) return this.showMessage(error.message);

            this.setState({ config: result });
        })

        if (!this.isBase()) {
            this.configRepository.getCurrentBaseRemoteConfig((result, error) => {
                if (error) return this.showMessage(error.message);

                const { config } = this.state;
                const newValues = Util.addTooltips(config.values, result.values, result.values, true);
                const newConfig = { values: newValues, version: config.version };

                this.setState({ baseConfig: result, config: newConfig });
            })
        }
    }

    incrementVersionIfNecessary = () => {
        const { config, hasCreatedNewVersion } = this.state;

        if (hasCreatedNewVersion) return;

        this.showMessage('This will create a new version 🍄');

        this.setState({
            config: {
                ...config,
                version : config.version + 1
            },
            hasCreatedNewVersion: true
        });
    }

    handleSave = () => {
        const { config } = this.state;

        if (!config.version) return;
        const formattedConfig = Util.formatSchema(config.values, config.version, this.isBase(), true);

        const callback = (error) => {
            if (error) return this.showMessage(error.message);

            this.setState({ hasCreatedNewVersion: false });
            this.showMessage('Saved');
        }

        this.isBase() ?
            this.configRepository.updateSchema(formattedConfig, callback) :
            this.configRepository.saveAppValues(formattedConfig, callback);
    }

    handleUpgrade = () => {
        const { baseConfig, config } = this.state;
        const newConfig = { ...baseConfig };

        for (const module in config.values) {
            if (module in newConfig.values) {
                for (const key in config.values[module]) {
                    if (key in newConfig.values[module]) {
                        newConfig.values[module][key].values = config.values[module][key].values;
                        if (newConfig.values[module][key].environmentSpecific !== config.values[module][key].environmentSpecific) {
                            const productionValue = newConfig.values[module][key].values[Util.ENV_TYPES.production];

                            Object.keys(newConfig.values[module][key].values).map(env => {
                                return newConfig.values[module][key].values[env] = productionValue;
                            });
                        }
                    }
                }
            }
        }

        this.setState({ config: newConfig });
    }

    handleDeprecateKey = (module, key) => {
        this.incrementVersionIfNecessary();
        
        this.setState(state => {
            const newConfig = { ...state.config };

            newConfig.values[module][key].versionRemoved = newConfig.version;

            return { config: newConfig }
        });
    }

    handleEnvSpecificChange = (module, key, isEnvSpecific) => {
        this.incrementVersionIfNecessary();

        this.setState(state => {
            const newConfig = { ...state.config };
            const productionValue = newConfig.values[module][key].values[Util.ENV_TYPES.production];

            newConfig.values[module][key].environmentSpecific = isEnvSpecific;

            Object.keys(newConfig.values[module][key].values).map(env => {
                return newConfig.values[module][key].values[env] = productionValue;
            });

            return { config: newConfig };
        });
    }

    handleChangeConfigKeyValue = (module, key, env, value) => {
        const newConfig = { ...this.state.config };
        const keyObj = newConfig.values[module][key];

        const formattedValue = Util.castValueToType(value, keyObj.type);

        if (keyObj.environmentSpecific) {
            keyObj.values[env] = formattedValue;
        } else {
            Object.keys(keyObj.values).map(env => keyObj.values[env] = formattedValue);
        }

        this.setState({ config: newConfig });
    }

    handleTooltipChange = (module, key, value) => {
        const newConfig = { ...this.state.config };

        newConfig.values[module][key].tooltip = value;

        this.setState({ config: newConfig });
    }

    handleAddModule = module => {
        if (!module) return;

        const { config } = this.state;
        const formattedModule = Util.formatKeyName(module);

        if (formattedModule in config.values) return this.showMessage(`Module ${formattedModule} already exists`);

        this.incrementVersionIfNecessary();

        this.setState(state => {
            const newConfig = {...state.config};

            newConfig.values[formattedModule] = {};

            return { config: newConfig };
        });
    }

    handleAddKey = (module, key, keyType) => {
        if (!key) return;

        const { config } = this.state;
        const formattedKey = Util.formatKeyName(key);

        if (config.values[module][formattedKey]) return this.showMessage(`Key ${formattedKey} already exists`);

        this.incrementVersionIfNecessary();

        this.setState(state => {
            const newConfig = { ...state.config };
            const newKey = {
                type                : keyType,
                values              : {},
                environmentSpecific : false,
                versionAdded        : newConfig.version
            };
            const values = {};
            const defaultValue = Util.getDefaultValueForType(keyType);

            Object.keys(Util.ENV_TYPES).map(env => values[Util.ENV_TYPES[env]] = defaultValue);

            newKey.values = values;
            newConfig.values[module][formattedKey] = newKey;

            return { config: newConfig };
        });
    }

    render = () => {
        const { config, baseConfig } = this.state;
        const title = `${this.isBase() ? '🏡 Base' : this.appCode} Remote config`;
        const isUpgradeAvailable = !this.isBase() && config.version !== baseConfig.version;
        const isModificationAllowed = this.isBase();
        const keyTypes = [
            Util.KEY_TYPES.string,
            Util.KEY_TYPES.bool,
            Util.KEY_TYPES.number
        ];

        return (
            <ConfigModuleList
                title={title}
                config={config}
                isUpgradeAvailable={isUpgradeAvailable}
                isModificationAllowed={isModificationAllowed}
                keyTypes={keyTypes}
                onSave={this.handleSave}
                onUpgrade={this.handleUpgrade}
                onAddModule={this.handleAddModule}
                onAddKey={this.handleAddKey}
                onChangeConfigKeyValue={this.handleChangeConfigKeyValue}
                onDeprecateKey={this.handleDeprecateKey}
                onEnvSpecificChange={this.handleEnvSpecificChange}
                onTooltipChange={this.handleTooltipChange}
            />
        );
    }
}

export default ManageRemoteConfig;